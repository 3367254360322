<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute no-print"
    :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu}"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline no-print"
          :class="{toggled: $sidebar.showSidebar}"
        >
          <button 
            type="button"
            class="navbar-toggler"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>

        <span class="navbar-brand">
          D-Plus
        </span>
      </div>
      
      <button
        v-if="false"
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show">
          <ul class="navbar-nav ml-auto">
            <base-dropdown
              tag="li"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <a
                slot="title"
                href="#"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <div class="photo">
                  <img src="/img/d-plus-color.png">
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>

              </a>
              <!-- <li class="nav-link">
                <router-link
                  :to="{ name: 'precios'}"
                  class="nav-item dropdown-item"
                >
                  Precios
                </router-link>
              </li> -->
              <li class="nav-link">
                <router-link
                  :to="{ name: 'sensores'}"
                  class="nav-item dropdown-item"
                >
                  Sensores
                </router-link>
              </li>
              <li class="nav-link">
                <router-link
                  :to="{ name: 'tarifas'}"
                  class="nav-item dropdown-item"
                >
                  Tarifas
                </router-link>
              </li>
              <!-- <li class="nav-link">
                <router-link
                  :to="{ name: 'usuarios'}"
                  class="nav-item dropdown-item"
                >
                  Usuarios
                </router-link>
              </li> -->
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <p class="nav-item dropdown-item" @click="logout">Salir</p>
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import Modal from '@/components/Modal';
  import VueRouter from 'vue-router'
  const { isNavigationFailure, NavigationFailureType } = VueRouter

  export default {
    components: {
      CollapseTransition,
      Modal
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
      };
    },
    methods: {
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },

      logout() {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push({name: 'login'}).catch(failure => {
            if (isNavigationFailure(failure)) {
              console.error('Error al hacer logout')
            }
          })
        })
      }
    }
  };
</script>
<style>
</style>
