<template>
  <div
    class="sidebar no-print"
    style="z-index: 3"
    :data="backgroundColor"
  >
    <div class="sidebar-wrapper" id="style-3">
      <i v-if="loading"
        class="fas fa-spinner fa-spin portafolio-loader"
      ></i>
      <div v-else>
        <div>
          <div class="logo">
            <a href="#"
            style="pointer-events: none"
              aria-label="sidebar mini logo"
              class="simple-text logo-mini">
              <div class="logo-img">
                <img
                  src="/img/d-plus-color-2.png"
                  alt="">
              </div>
            </a>
            <span class="simple-text logo-normal">
              Plantas
            </span>
          </div>
          <ul class="nav">
            <li v-for="planta in plantas" :key="planta.id" class="nav-item">
              <router-link
                :to="{ name: 'reportes-planta-visita', params: { id: planta.id }}"
              >
                <i class="tim-icons icon-chart-bar-32"></i>
                <p>{{planta.nombre_visible}}</p>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  // import SidebarLink from "./SidebarLink";
  import DeltaDropdown from "../DeltaDropdown";

  export default {
    name: 'invitado-side-bar',
    props: {
      title: {
        type: String,
        default: "Reportes 4TC"
      },
      backgroundColor: {
        type: String,
        default: "vue"
      },
      activeColor: {
        type: String,
        default: "success",
        validator: value => {
          let acceptedValues = [
            "primary",
            "info",
            "success",
            "warning",
            "danger"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose,
        addLink: this.addLink,
        removeLink: this.removeLink
      };
    },
    components: {
      // SidebarLink,
      DeltaDropdown
    },
    computed: {
      ...mapGetters({
        plantas: 'invitados/plantas',
      }),
      /**
       * Styles to animate the arrow near the current active sidebar link
       * @returns {{transform: string}}
       */
      arrowMovePx() {
        return this.linkHeight * this.activeLinkIndex;
      },
      shortTitle() {
        return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase();
      }
    },
    data() {
      return {
        linkHeight: 65,
        activeLinkIndex: 0,
        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,
        links: [],
        loading: true,
      };
    },
    methods: {
      findActiveLink() {
        this.links.forEach((link, index) => {
          if (link.isActive()) {
            this.activeLinkIndex = index;
          }
        });
      },
      addLink(link) {
        const index = this.$slots.links.indexOf(link.$vnode);
        this.links.splice(index, 0, link);
      },
      removeLink(link) {
        const index = this.links.indexOf(link);
        if (index > -1) {
          this.links.splice(index, 1);
        }
      }
    },
    created(){
    },
    async mounted() {
      // this.$watch("$route", this.findActiveLink, {
      //   immediate: true
      // });

      if(this.plantas.length==0){
        this.$store.dispatch('invitados/fetchPlantas');
      }
      
      this.loading = false;
    }
  };
</script>


<style lang="scss" scoped>
.portafolio-loader {
  color: white;
  font-size: 20px;
  margin: 30px
}
</style>
