<template>
  <div class="row section-footer">
    <div class="flex-footer">
      <div>
        <b>{{sigla}}-O&M{{formatDate(fecha_reporte, 'YY', localI18n.locale)}}-</b><b style="text-transform:capitalize">{{formatDate(fecha_reporte, 'MMMM', localI18n.locale)}}</b>
        <br>
        Delta Activos
      </div>
      <div>
        {{nombre_visible}} <br>
        {{ localI18n.t('reporte.footer.titulo') }}
      </div>
      <div style="width: 120px">
        <div class="right">
          <a href="https://deltactivos.cl" target="_blank">
            <img
              class="cover-logo"
              src="/img/d-plus-color.png"
              alt="D-Plus Logo"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
var localeData = require('dayjs/plugin/localeData');
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween)
require('dayjs/locale/es');
dayjs.locale('es');

export default {
  name: 'reporte-footer',
  props: {
    fecha_reporte: '',
    nombre_visible: '',
    portafolio: {
      type: String,
      default: '--'
    },
    sigla: {
      type: String,
      default: '--'
    },
    localI18n: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      portafolios_delta: ['DAGDA', 'ENLASA', 'SINVEST1', 'SINVEST2']
    }
  },
  methods: {
    formatDate(date, formato, locale){
      return date.locale(locale).format(formato);
    }
  },
}
</script>

<style lang="scss" scoped>

div.row {
  margin-bottom: 20px;
  /* border: solid 1px blue; */
}

.section-footer {
  position: absolute;
  bottom: 0;
  width: 175mm;
  
}

.row.section-footer {
  margin-bottom: 2cm;
}

.flex-footer {
  display: flex;
  justify-content: space-between; 
  border-top: solid 1.5px #333;
  padding-top: 5px;
  font-size: 13px;
  text-align: center;
  width: 100%;
  font-style: italic;

 .right {
    position: absolute;
    right: 0.05cm;
    // bottom: 0.25cm;
    display: flex;
    flex-direction: column;
    align-items: center;

    a > .cover-logo {
      height: 40px;
      width: 40px;
      // margin-bottom: 10px;
      // position: absolute;
      // top: -70px;
      // right: 2cm;
      border-radius: 0;
    }
  }
}

</style>>