<template>
  <div>
    <h1>Asignador de Valores</h1>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Descargar Tarifas</h2>
          <div class="row">
            <div class="col-12 col-md-6">
              <label class="control-label">
                Seleccionar Fechas
              </label>
              <br>
              <date-picker 
                class="datepicker"
                v-model="fechaDescargaTarifas"
                valueType="format"
                type="month"
                format="YYYY-MM"
              >
              </date-picker>
            </div>
            <div class="col-12 col-md-6">
              <button class="btn" @click="descargarTarifas()">
              Descargar
            </button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Listado de Tarifas</h2>

          <div class="table-responsive">
            <table class="table tablesorter table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colspan="2">Inyeccion</th>
                  <th colspan="2">Autoconsumo</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th>Nombre</th>
                  <th>Tipo Contrato</th>
                  <th>¿Usa dcto. diferido?</th>
                  <th>% de dcto. o valor fijo</th>
                  <th>Tipo Dcto</th>
                  <th>% de dcto. o valor fijo</th>
                  <th>Tipo Dcto</th>
                  <th>Comuna</th>
                  <th>Distribuidora</th>
                  <th>Tarifa</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in plantas" :key="index">
                  <td>
                    <router-link
                      :to="{ name: 'planta', params: { id: item.id }}"
                    >
                      {{item.nombre}}
                    </router-link>
                  </td>
                  <td>
                    <select class="form-control"  v-model="item.tipo_contrato">
                      <option value="FIJO">Fijo</option>
                      <option value="VARIABLE">Variable</option>
                    </select>
                    <span v-if="item.id in errores && 'tipo_contrato' in errores[item.id]" style="color: red">
                      {{ errores[item.id].tipo_contrato }}
                    </span>
                  </td>
                  <td>
                    <base-checkbox v-model="item.usa_dcto_diferida" />
                  </td>
                  <td>
                    <base-input 
                      placeholder="% de Contrato"
                      v-model="item.porc_contrato"
                    >
                    </base-input>
                    <span v-if="item.id in errores && 'porc_contrato' in errores[item.id]" style="color: red">
                      {{ errores[item.id].porc_contrato }}
                    </span>
                  </td>
                  <td>
                    <select class="form-control"  v-model="item.tipo_descuentos">
                      <option v-for="tipo_descuentos in tipos_descuentos" :key="tipo_descuentos.codigo" :value="tipo_descuentos.codigo">
                        {{tipo_descuentos.nombre}}
                      </option>
                    </select>
                    <span v-if="item.id in errores && 'tipo_descuentos' in errores[item.id]" style="color: red">
                      {{ errores[item.id].tipo_descuentos }}
                    </span>
                  </td>
                  <td>
                    <base-input 
                      placeholder="% de Contrato"
                      v-model="item.porc_contrato_autoconsumo"
                    >
                    </base-input>
                    <span v-if="item.id in errores && 'porc_contrato_autoconsumo' in errores[item.id]" style="color: red">
                      {{ errores[item.id].porc_contrato_autoconsumo }}
                    </span>
                  </td>
                  <td>
                    <select class="form-control"  v-model="item.tipo_descuentos_autoconsumo">
                      <option v-for="tipo_descuentos in tipos_descuentos" :key="tipo_descuentos.codigo" :value="tipo_descuentos.codigo">
                        {{tipo_descuentos.nombre}}
                      </option>
                    </select>
                    <span v-if="item.id in errores && 'tipo_descuentos_autoconsumo' in errores[item.id]" style="color: red">
                      {{ errores[item.id].tipo_descuentos_autoconsumo }}
                    </span>
                  </td>
                  <td>
                    <multiselect v-model="item.comuna" :options="comunas"
                      placeholder="Comuna" label="nombre" track-by="nombre">
                    </multiselect>
                    <span v-if="item.id in errores && 'comuna' in errores[item.id]" style="color: red">
                      {{ errores[item.id].comuna }}
                    </span>
                  </td>
                  <td>
                    <select class="form-control"  v-model="item.distribuidora">
                      <option v-for="distribuidora in distribuidoras" :key="distribuidora.codigo" :value="distribuidora.codigo">
                        {{distribuidora.nombre}}
                      </option>
                    </select>
                    <span v-if="item.id in errores && 'distribuidora' in errores[item.id]" style="color: red">
                      {{ errores[item.id].distribuidora }}
                    </span>
                  </td>
                  <td>
                    <select class="form-control"  v-model="item.tarifa">
                      <option v-for="tarifa in tarifas" :key="tarifa.codigo" :value="tarifa.codigo">
                        {{tarifa.codigo}}
                      </option>
                    </select>
                    <span v-if="item.id in errores && 'tarifa' in errores[item.id]" style="color: red">
                      {{ errores[item.id].tarifa }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          

          <button class="btn" @click="guardarTabla">
            Guardar Datos
          </button>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { BaseTable } from "@/components";
import VGrid from "@revolist/vue-datagrid";

import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);


export default {
  name: 'asignador-page',
  components: {
    VGrid,
    Multiselect,
    BaseTable,
    DatePicker
  },
  data(){
    return {
      portafolio_codigo: '',
      portafolio: {},
      loaded_data: false,
      distribuidoras: [],
      comunas: [],
      tarifas: [],
      tipos_descuentos: [],
      plantas: [],
      errores: {},
      fechaDescargaTarifas: null
    }
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },
    
    async guardarTabla(){
      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/asignador/plantas',
          data: {
            plantas: this.plantas,
          }
        });

        let data = res.data;
        let n_correctas = data.n_correctas;
        let n_errores = data.n_errores;
        this.errores = data.errors;

        
        if(n_correctas > 0){
          this.notifyVue(`${n_correctas} plantas guardadas.`, 'info');
        }

        if(n_errores > 0){
          this.notifyVue(`${n_errores} plantas con errores.`, 'warning');
        }
      }
      catch(error){
        this.notifyVue('Error al guardar', 'danger');
        console.error(error)
      }
    },

    async descargarTarifas(){
      // revisar eleccion de fecha
      if(this.fechaDescargaTarifas===null){
        this.notifyVue('No se ha escogido una fecha', 'danger');
        return;
      }
      
      let ano = this.fechaDescargaTarifas.split('-')[0];
      let mes = this.fechaDescargaTarifas.split('-')[1];

      try {
        this.notifyVue('Generando archivo', 'info');
        let res = await this.$http({
          method: 'POST',
          url: '/api/tarifas/descargar',
          data: {
            portafolio: this.portafolio_codigo,
            mes,
            ano
          },
          responseType: 'blob',
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        // fileLink.setAttribute('download', `${filename}.${extension}`);
        let filename = `Tarifas ${this.portafolio.nombre} - ${ano}-${mes}.xlsx`
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo descargado', 'success');
      } catch (error) {
        this.notifyVue('Error al obtener archivo', 'danger');
        console.error(error);
      }
    }
  },

  async mounted(){
    this.portafolio_codigo = this.$route.query.portafolio;

    this.$http({
      method: 'GET',
      url: '/api/portafolio/'+this.portafolio_codigo,
    })
    .then(res => {
      console.log('Portafolio obtenido');
      this.portafolio = res.data;
    })
    .catch(e => {
      console.error(e);
    });

    // pedir listado de distribuidoras
    this.$http({
      method: 'GET',
      url: '/api/distribuidoras/min',
    })
    .then(res => {
      console.log('Distribuidoras obtenidas');
      this.distribuidoras = res.data;
    })
    .catch(e => {
      console.error(e);
    });

    // pedir listado de comunas
    this.$http({
      method: 'GET',
      url: '/api/comunas/min',
    })
    .then(res => {
      console.log('Comunas obtenidas');
      this.comunas = res.data;
    })
    .catch(e => {
      console.error(e);
    });

    // pedir listado de tarifas
    this.$http({
      method: 'GET',
      url: '/api/tarifas/min',
    })
    .then(res => {
      console.log('Tarifas obtenidas');
      this.tarifas = res.data;
    })
    .catch(e => {
      console.error(e);
    });

    // pedir listado de tipos_descuentos
    this.$http({
      method: 'GET',
      url: '/api/tipos_descuentos/min',
    })
    .then(res => {
      console.log('Tipos de descuentos obtenidos');
      this.tipos_descuentos = res.data;
    })
    .catch(e => {
      console.error(e);
    });

    let res = await this.$http({
      method: 'GET',
      url: '/api/asignador/plantas',
      params: { portafolio: this.portafolio_codigo }
    });

    this.plantas = res.data;
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  overflow: initial;
}

</style>