<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
  export default {
    metaInfo() {
      return {
        title: this.title,
        titleTemplate: 'DeltActivos - D-Plus %s'
      };
    },
    data(){
      return {
        title: ''
      }
    },
    methods: {
      // toggleNavOpen() {
      //   let root = document.getElementsByTagName('html')[0];
      //   root.classList.toggle('nav-open');
      // }
    },
    mounted() {
      // this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
    },
    created(){
      console.log('App created');
      // TODO: terminar
      // this.$http.interceptors.response.use(undefined, function (err) {
      //   console.log(err.status);
      //   console.log('hola');
      //   console.log(err.config);
      //   console.log(err.response);
      //   return new Promise(function (resolve, reject) {
      //     if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
      //       console.log('aqui');
      //       // this.$store.dispatch(logout)
      //     }
      //     throw err;
      //   });
      // });
    }
  };
</script>

<style lang="scss"></style>
