import {Line, mixins} from 'vue-chartjs';

export default {
  name: 'custom-line-chart',
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null
    },
    extraOptions: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      ctx: null
    }
  },
  methods: {

  },
  mounted() {
    this.renderChart(this.chartData, this.extraOptions)
  },
  watch: {
    chartData () {
      // this.$data._chart.update();
      this.renderChart(this.chartData, this.extraOptions)
    }
  }
}