<template>
  <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent no-print">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <span class="navbar-brand">D-Plus - Invitado</span>
      </div>

      <collapse-transition>
        <div class="collapse navbar-collapse show">
          <ul class="navbar-nav ml-auto">
            <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="photo">
                  <img src="/img/d-plus-color-2.png">
                </div>
              </a>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import VueRouter from 'vue-router'
  const { isNavigationFailure, NavigationFailureType } = VueRouter

  export default {
    components: {
      CollapseTransition,
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
      };
    },
    methods: {
    }
  };
</script>
<style>
</style>
