import { render, staticRenderFns } from "./SinReporteFinanciero.vue?vue&type=template&id=28e270de&scoped=true"
import script from "./SinReporteFinanciero.vue?vue&type=script&lang=js"
export * from "./SinReporteFinanciero.vue?vue&type=script&lang=js"
import style0 from "./SinReporteFinanciero.vue?vue&type=style&index=0&id=28e270de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e270de",
  null
  
)

export default component.exports