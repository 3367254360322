<template>
  <div
    class="sidebar no-print"
    :class="{'sidebar-collapsed': $sidebar.showSidebar}"
    style="z-index: 3"
    :data="backgroundColor"
  >
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <i v-if="loading"
        class="fas fa-spinner fa-spin portafolio-loader"
      ></i>
      <div v-else>
        <div v-for="grupo in grupos_portafolios" :key="'grupo_'+grupo.codigo">
          <div class="logo">
            <a
              href="#"
              style="pointer-events: none"
              aria-label="sidebar mini logo"
              class="simple-text logo-mini"
            >
              <div class="logo-img">
                <img
                  src="/img/d-plus-positivo.png"
                  alt="">
              </div>
            </a>
            <router-link
              :to="{ name: 'general', query: { grupo: grupo.codigo }}"
            >
              <span class="simple-text header-portafolio">
                {{grupo.nombre}}
              </span>
            </router-link>
          </div>
          <ul class="nav">
            <li v-for="portafolio in grupo.portafolios" :key="portafolio.codigo" class="nav-item">
              <delta-dropdown :portafolio=portafolio :loading=loading />
            </li>
          </ul>
        </div>
      </div>
      

      <!-- Crear Plantas -->
      <div class="logo">
        <a href="#"
          aria-label="sidebar mini logo"
          class="simple-text logo-mini">
          <div class="logo-img">
            <img
              src="/img/d-plus-positivo.png"
              alt="">
          </div>
        </a>
        <span class="simple-text header-portafolio">
          Crear Plantas
        </span>
      </div>
      <ul class="nav">
        <li class="nav-item">
          <router-link
            :to="{ name: 'plantas-create'}"
          >
            <i class="tim-icons icon-chart-pie-36"></i>
            <p>Fronius</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'plantas-create-huawei'}"
          >
            <i class="tim-icons icon-chart-pie-36"></i>
            <p>Huawei</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'plantas-create-sma'}"
          >
            <i class="tim-icons icon-chart-pie-36"></i>
            <p>SMA</p>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'plantas-create-ingeteam'}"
          >
            <i class="tim-icons icon-chart-pie-36"></i>
            <p>Ingeteam</p>
          </router-link>
        </li><li class="nav-item">
          <router-link
            :to="{ name: 'plantas-create-fusionsolar'}"
          >
            <i class="tim-icons icon-chart-pie-36"></i>
            <p>FusionSolar</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import SidebarLink from "./SidebarLink";
  import DeltaDropdown from "../DeltaDropdown";

  export default {
    props: {
      title: {
        type: String,
        default: "Reportes 4TC"
      },
      backgroundColor: {
        type: String,
        default: "vue"
      },
      activeColor: {
        type: String,
        default: "success",
        validator: value => {
          let acceptedValues = [
            "primary",
            "info",
            "success",
            "warning",
            "danger"
          ];
          return acceptedValues.indexOf(value) !== -1;
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide() {
      return {
        autoClose: this.autoClose,
        addLink: this.addLink,
        removeLink: this.removeLink
      };
    },
    components: {
      SidebarLink,
      DeltaDropdown
    },
    computed: {
      ...mapGetters({
        grupos_portafolios: 'grupos/grupos',
      }),
      /**
       * Styles to animate the arrow near the current active sidebar link
       * @returns {{transform: string}}
       */
      arrowMovePx() {
        return this.linkHeight * this.activeLinkIndex;
      },
      shortTitle() {
        return this.title.split(' ')
          .map(word => word.charAt(0))
          .join('').toUpperCase();
      }
    },
    data() {
      return {
        linkHeight: 65,
        activeLinkIndex: 0,
        windowWidth: 0,
        isWindows: false,
        hasAutoHeight: false,
        links: [],
        loading: true,
      };
    },
    methods: {
      findActiveLink() {
        this.links.forEach((link, index) => {
          if (link.isActive()) {
            this.activeLinkIndex = index;
          }
        });
      },
      addLink(link) {
        const index = this.$slots.links.indexOf(link.$vnode);
        this.links.splice(index, 0, link);
      },
      removeLink(link) {
        const index = this.links.indexOf(link);
        if (index > -1) {
          this.links.splice(index, 1);
        }
      }
    },
    created(){
    },
    async mounted() {
      // this.$watch("$route", this.findActiveLink, {
      //   immediate: true
      // });

      if(this.grupos_portafolios.length==0){
        this.$store.dispatch('grupos/fetchPortafolios');
      }
      
      this.loading = false;
    }
  };
</script>


<style lang="scss" scoped>
.portafolio-loader {
  color: white;
  font-size: 20px;
  margin: 30px
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidebar-collapsed {
  width: 0px !important;
}

</style>
