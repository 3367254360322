<template>
  <div>
    <div v-if="loading">
      Cargando datos...
    </div>
    <div v-else>
      <div v-if="reporte">
        <sin-reporte-financiero 
          :localI18n="localI18n"
        />
      </div>
      <div v-else>
        <h1>No se han encontrado datos</h1>
      </div>
    </div>
  </div>
</template>

<script>
import VueI18n from 'vue-i18n';
import { messages } from '../../i18n'; 
import SinReporteFinanciero from './reportes/SinReporteFinanciero.vue';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
var localeData = require('dayjs/plugin/localeData');
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween)
require('dayjs/locale/es');
dayjs.locale('es');

// var backend_db_instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_DB_URL,
// });

export default {
  name: 'reporte-pdf-public',
  components: {
    SinReporteFinanciero,
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: '%s'
    };
  },
  data(){
    return {
      title: '',
      tipo: 'SINREPORTEFINANCIERO',
      reporte: null,
      planta: null,
      loading: true,
      localI18n: new VueI18n({
        locale: 'es', // default locale
        fallbackLocale: 'es',
        messages,
      }),
    }
  },
  methods: {
    switchLanguage(lang) {
      this.localI18n.locale = lang;
    }
  },
  created(){
    // get lang from query params or default to 'es'
    const lang = this.$route.query.lang || 'es';
    const availableLangs = ['es', 'en'];

    if (!availableLangs.includes(lang)) {
      this.$router.push({ query: { lang: 'es' } });
    }

    if (lang) {
      this.localI18n.locale = lang;
    }
  },
  async mounted(){
    // let id_planta = this.$route.params.id;
    let codigo = this.$route.params.codigo;

    try {
      let res = await this.$http({
        method: 'GET',
        url: 'api/reportes/'+codigo+'/min',
      });

        this.tipo = res.data.tipo;
        this.reporte = res.data.tiene_reporte;

        let fecha = dayjs(res.data.fecha_reporte)
        this.title = `Reporte ${res.data.nombre} - ${fecha.format('YYYY-MM')}`
    } catch (error) {
      console.log(error);
    }

    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
</style>