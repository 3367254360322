<template>
  <div>
    <div v-if="false">
      <button class="btn btn-info" @click="switchLanguage('en')">English</button>
      <button class="btn btn-info" @click="switchLanguage('es')">Español</button>
    </div>
    
    <div v-if="loading">
      Cargando datos...
    </div>
    <div v-else>
      <div v-if="reporte">
        <sin-reporte-financiero
          :localI18n="localI18n"
        />
      </div>
      <div v-else>
        <h1>No se han encontrado datos</h1>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import VueI18n from 'vue-i18n';
import { messages } from '@/i18n'; 
import SinReporteFinanciero from './reportes/SinReporteFinanciero.vue';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
var localeData = require('dayjs/plugin/localeData');
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween)
require('dayjs/locale/es');
dayjs.locale('es');


// var backend_db_instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_DB_URL,
// });

export default {
  name: 'planta-pdf',
  components: {
    SinReporteFinanciero,
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: '%s'
    };
  },
  data(){
    return {
      title: null,
      tipo: 'SINREPORTEFINANCIERO',
      reporte: null,
      planta: null,
      loading: true,
      localI18n: new VueI18n({
        locale: 'es', // default locale
        fallbackLocale: 'es',
        messages,
      }),
    }
  },
  methods: {
    switchLanguage(lang) {
      this.localI18n.locale = lang;
    }
  },
  created(){
    // get lang from query params or default to 'es'
    const lang = this.$route.query.lang || 'es';
    const availableLangs = ['es', 'en'];

    if (!availableLangs.includes(lang)) {
      this.$router.push({ query: { lang: 'es' } });
    }

    if (lang) {
      this.localI18n.locale = lang;
    }
  },
  async mounted(){
    let id_planta = this.$route.params.id;
    let id_reporte = this.$route.params.id_reporte;

    try {
      let res = await this.$http({
        method: 'GET',
        url: '/api/plantas/'+id_planta+'/min'
      });

      this.planta = res.data;
      this.tipo = res.data.tipo_reporte;

      let res_reporte = await this.$http({
        method: 'GET',
        url: 'api/plantas/'+id_planta+'/reportes/'+id_reporte+'/pdf',
      });
      this.reporte = res_reporte.data.datos_reporte;

      let fecha = dayjs(this.reporte.fecha_reporte).add(12, 'hour');
      this.title = `Reporte ${this.planta.nombre} - ${fecha.format('YYYY-MM')}`;
    } catch (error) {
      console.log(error);
    }

    this.loading = false;
  }
}
</script>

<style lang="scss" scoped>
// @import url('https://fonts.googleapis.com/css?family=Lusitana&display=swap');

// body {
//   // font-family: 'Lusitana', serif;
//   font-family: Georgia, 'Times New Roman', Times, serif;
// }

// .page {
//   font-family: 'Lusitana', serif;
//   // font-family: Georgia, 'Times New Roman', Times, serif;
// }

// /**************/
// /** Reporte **/
// /************/

// .cleaning-comments {
//   position: absolute;
//   bottom:60px
// }

// .cleaning-photos {
//   border-bottom: 5px;
// }

// .cleaning-table-values {
//   border-collapse: separate;
//   width: 450px;
//   margin: 0 auto;
//   border-top: solid 1px #333;
//   /* border-bottom: solid 1px #333; */
// }

// .cleaning-table-values tr:last-child td{
//   border-top: solid 1px #333; 
//   border-radius: 0px;
// }

// .cleaning-table-values td {
//   padding: 15px 21px;
// }

// .cleaning-table {
//   border: solid 1px #333;
//   border-collapse: separate;
// }

// .cleaning-table td:first-child {
//   border-right: solid 1px #333;
//   border-radius: 0px;
// }

// .appendix-table {
//   & tbody tr td {
//     padding: 2px 4px;
//     font-size: 11px;
//   }
// }


// .appendix-table.horizontal-table {
//   transform: rotate(270deg);
//   position: absolute;
//   top: 300px;
//   right:7px;
//   font-size: 13px;
// }

// .appendix-table.horizontal-big-table {
//   transform: rotate(270deg) scale(0.85);
//   position: relative;
//   font-size: 11px;
// }

// .appendix-table.horizontal-giant-table {
//   transform: rotate(270deg) scale(0.7);
//   position: relative;
//   font-size: 9px;
// }

// .tb-15 {
//   top: 80px;
//   right: 70px;
// }
// .tb-16 {
//   top: 50px;
//   right: 90px;
// }
// .tb-17 {
//   top: 50px;
//   right: 110px;
// }
// .tb-18 {
//   top: 50px;
//   right: 130px;
// }
// .tb-19 {
//   top: 50px;
//   right: 150px;
// }
// .tb-20 {
//   top: 50px;
//   right: 170px;
// }
// .tb-21 {
//   top: 50px;
//   right: 190px;
// }
// .tb-22 {
//   top: 50px;
//   right: 210px;
// }
// .tb-23 {
//   top: 50px;
//   right: 230px;
// }
// .tb-24 {
//   top: 50px;
//   right: 250px;
// }
// .tb-25 {
//   top: 70px;
//   right: 320px;
// }
// .tb-26 {
//   top: 60px;
//   right: 340px;
//   transform: rotate(270deg) scale(0.68) !important;
// }
// .tb-27 {
//   top: 60px;
//   right: 360px;
//   transform: rotate(270deg) scale(0.66) !important;
// }
// .tb-28 {
//   top: 60px;
//   right: 380px;
//   transform: rotate(270deg) scale(0.64) !important;
// }
// .tb-29 {
//   top: 60px;
//   right: 400px;
//   transform: rotate(270deg) scale(0.62) !important;
// }
// .tb-30 {
//   top: 60px;
//   right: 420px;
//   transform: rotate(270deg) scale(0.6) !important;
// }


// .appendix-table.horizontal-table tbody tr td {
//   padding: 9px 5px;
//   font-size: 13px;
// }

// #smart-container {
//   width: 100%;
//   height: 400px;
//   position: relative;
//   text-align: center;
// }

// #smart-container img {
//   width: 100%;
//   height: 100%;
// }

// #en-prod-text {
//   position: absolute; 
//   top: 49%;
//   left: 33%;
//   line-height: 15px;
//   max-width: 70px;
// }

// #con-dir-text {
//   position: absolute; 
//   top: 14%;
//   left: 61%;
//   line-height: 15px;
//   max-width: 70px;
// }

// #inj-gr-text {
//   position: absolute; 
//   top: 88%;
//   left: 66%;
//   line-height: 15px;
//   max-width: 70px;
// }

// #en-prod-val {
//   position: absolute; 
//   top: 44%;
//   left: 34%;
// }

// #con-dir-val {
//   position: absolute; 
//   top: 24%;
//   left: 63%;
// }

// #inj-gr-val {
//   position: absolute; 
//   top: 77%;
//   left: 68%;
// }


// div.row {
//   margin-bottom: 20px;
//   /* border: solid 1px blue; */
// }

// #cover-logo {
//   text-align: right;
//   margin-bottom: 50px;
// }

// .cover-contact-info {
//   flex-direction: column;
// }

// #cover-info {
//   margin-bottom: 75px;
// }

// .contact-name {
//   font-weight: bold;
//   font-size: 20px;
// }

// .section-header {
//   margin-bottom: 1px;
//   padding-bottom: 0.5cm;
//   border-bottom: solid 1px;

//   > div.left {
//     font-size: 15px;
//     font-weight: 350;
//   }

//   > hr {
//     margin: 25px auto 1px auto;
//     border: none;
//     border-top: solid 1px #333;
//     height: 1px;
//     background-color: transparent;
//   }
// }


// .cover-header {
//   margin-bottom: 2cm !important;
//   padding-bottom: 0.5cm;
//   // border-bottom: solid 1px;

//   > .left {
//     font-size: 15px;
//     font-weight: bolder;
//   }

//   > .right {
//     > .cover-logo {
//       height: 70px;
//       position: absolute;
//       top: 70px;
//       right: 2cm;
//       border-radius: 0;
//     }
//   }

//   // > hr {
//   //   margin: 25px auto 1px auto;
//   //   border: none;
//   //   border-top: solid 1px #333;
//   //   height: 1px;
//   //   background-color: transparent;
//   // }
// }


// caption {
//   margin-top: 10px;
//   // caption-side: bottom;
//   caption-side: top;
//   text-align: center;
//   font-size: 12px;
//   font-style: italic;
  
//   > b {
//     font-size: 13px;
//   }
// }


// .section-graph {
//   text-align: center;
//   flex-direction: column;
//   /* max-height: 300px; */
//   /* overflow: hidden; */

//   > div > img {
//     max-height: 270px;
//     width: 100%;
//     /* margin-bottom: 15px; */
//   }

//   > span {
//     // color: black;
//     font-style: italic;
//     margin-top: 0.5cm;
//   }
// }

// // .section-graph > div > img {
// //   max-height: 270px;
// //   width: 100%;
// //   /* margin-bottom: 15px; */
// // }

// .top-caption {
//   caption-side: top;
// }

// /* .section-table {
//   max-height: 250px;
// } */

// .section-table > table {
//   // line-height: 0px;
//   font-size: 12px;
//   width: 100%;
//   color: black;

//   tbody {
//     font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; 
//   }
// }

// .report-borderless-table {
//   border-top: solid 1px black;
//   border-bottom: solid 1px black;
//   width: 100%;

//   > thead {
//     border-bottom: solid 1px black;
//     text-align: center;
//   }

//   > tbody > tr > td:first-child {
//     font-weight: bold;
//   }

//   > tbody > tr > td:not(:first-child) {
//     text-align: center !important;
//   }
// }

// .report-inverter-table {
//   // border-top: solid 1px black;
//   // border-bottom: solid 1px black;
//   width: 100%;

//   > tbody {
//     border-bottom: solid 1px black;
//     border-top: solid 1px black;
//   // border-bottom: solid 1px black;
//   }

//   > thead > tr > th:first-child {
//     border-right: solid 1px black;
//   }

//   > tbody > tr > td:first-child {
//     font-weight: bold;
//     border-right: solid 1px black;
//   }
  
//   > tfoot > tr > td:first-child {
//     font-weight: bold;
//     border-right: solid 1px black;
//   }
// }


// .report-bordered-historic-table {
//   border-collapse: collapse;
//   width: 100%;
//   margin: 0 auto;

//   // > tbody > tr > td {
//   //   border: solid 1px black;
//   // }

//   > thead {
//     border-bottom: solid 1px black;
//   }

//   .border-right-table {
//     border-right: solid 1px black;
//   }
// }

// .report-bordered-table {
//   border-collapse: collapse;
//   margin: 0 auto;
  
// }

// .report-bordered-table > tbody > tr > td, .report-bordered-table > thead > tr > th {
//   border: solid 1px #333;
// }


// .table-footer {
//   border-top: solid 2px #333;
//   font-weight: bold;
// }

// .section-list {
//   font-size: 14px;
// }

// .report-graph {
//   height: 280px;
// }

// // .section-list > ul > li:nth-child(6) > ul > li {
// //   padding: 2px 15px 2px 15px;
// // }

// // .section-list ul li, .section-list ul li ul li {
// //   padding: 6px;
// // }

// .section-list > ul > li {
//   list-style-type: square;
//   margin-left: 30px;
// }

// .span-avlt {
//   position: absolute;
//   left: 400px;
// }

// /* .page:first-of-type {
//   margin-top: 100px;
// } */

// .page {
//   position: relative;
//   width: 210mm;
//   min-height: 297mm;
//   padding: 0mm;
//   margin: 10mm auto;
//   border: 1px #D3D3D3 solid;
//   border-radius: 5px;
//   background: #ccc;
//   /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */

// }

// .subpage {
//   /* padding: 1cm; */
//   padding: 1cm 2cm;
//   /* border: 5px #ccc solid; */
//   height: 297mm;
//   background-color: white;
//   /* outline: 2cm whitesmoke solid; */

//   &.report-cover {
//     padding-top: 2cm;

//     &#cover-title {
//       margin-bottom: 0.25cm;
//     }

//     &#cover-title-2 {
//       margin-bottom: 1cm;
//       font-weight: bolder;

//       > h1 {
//         line-height: 1.1cm;
//       }
//     }
//   }
// }

// // .subpage.report-cover {
// //   padding-top: 2cm;
// //   padding-left: 2.5cm;
// //   padding-right: 2.5cm;
// // }

// .row.section-title {
//   margin-bottom: 10px;
// }

// .section-footer {
//   position: absolute;
//   bottom: 0;
//   width: 175mm;
  
// }

// .row.section-footer {
//   margin-bottom: 2cm;
// }

// .flex-footer {
//   display: flex;
//   justify-content: space-between; 
//   border-top: solid 1.5px #333;
//   padding-top: 5px;
//   font-size: 13px;
//   text-align: center;
//   width: 100%;
//   font-style: italic;
// }


// .mjx-chtml.MJXc-display {
//   font-size: 25px;
// }

// .mjx-sup {
//   font-size: 80%;
// }

// @media print {
//   @page {
//     size: A4;
//     margin: 0;
//   }

//   html, body {
//     width: 210mm;
//     height: 297mm;
//   }

//   /* body {
//     background-color: rgb(166, 213, 252);
//   } */

//   header {
//     display: none !important;
//   }
  
//   .no-print {
//     display: none;
//   }

//   .sidebar {
//     display: none !important;
//   }

//   .page:first-of-type {
//     margin-top: 0;
//   }

//   .page {
//     margin: 0;
//     border: initial;
//     // border: solid 1px red;
//     border-radius: initial;
//     width: initial;
//     min-height: initial;
//     box-shadow: initial;
//     background: initial;
//     page-break-after: always;
//   }

//   .mjx-chtml.MJXc-display {
//     display: inline;
//   }

//   .main-panel {
//     border-top: 0;
//   }

//   footer.footer {
//     display: none;
//   }
// }


</style>