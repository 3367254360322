<template>
<div>
  <div class="container">
    <div class="header-body text-center">
      <h1>Usuario</h1>
    </div>
  </div>
  <div class="container">
    <div class="col-md-6 mt-3 ml-auto mr-auto">
      <form @submit.prevent="handleSubmit()">
        <card>
          <div>
            <base-input
              required
              v-model="first_name"
              type="text"
              placeholder="Nombre"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>
            <base-input
              required
              v-model="last_name"
              type="text"
              placeholder="Apellido"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>
            <base-input
              required
              v-model="email"
              type="email"
              placeholder="Correo"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>
            <multiselect
              v-model="grupo" :options="grupos"
              placeholder="Seleccionar Rol" label="name" track-by="id">
            </multiselect>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="success" class="mb-3" size="lg" block>
              Guardar Cambios
            </base-button>
          </div>
        </card>
      </form>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'usuario',
  data(){
    return {
      id: null,
      email: '',
      first_name: '',
      last_name: '',
      grupo: null,
      grupos: [],
    }
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    async handleSubmit(){
      if(this.first_name == "" || this.last_name == "" || this.email == ""){
        this.notifyVue('No deben quedar campos vacíos', 'warning');
        return;
      }
      
      if(this.grupo == null){
        this.notifyVue('Se debe escoger un rol', 'warning');
        return;
      }
      try {
        let res = await this.$http({
          method: 'PUT',
          url: 'api/usuarios/'+this.id,
          data: {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            group: this.grupo.id
          }
        })

        console.log(res);
        this.notifyVue('Se han guardado los datos', 'success');

      } catch (error) {
        this.notifyVue('No se han podido guardar los datos', 'danger');
        console.log(error);
      }
    }
  },
  async mounted(){
    let id = this.$route.params.id;

    try {
      let res_grupos = await this.$http({
        method: 'GET',
        url: '/api/grupos'
      });

      this.grupos = res_grupos.data;

      let res = await this.$http({
        method: 'GET',
        url: '/api/usuarios/'+id
      });

      this.id = res.data.id;
      this.email = res.data.email;
      this.first_name = res.data.first_name;
      this.last_name = res.data.last_name;
      this.grupo = res.data.groups[0];
    } catch (error) {
      this.notifyVue('No se ha podido cargar los datos del Usuario', 'danger');
      console.log(error);
    }
  }
}
</script>

<style>

</style>