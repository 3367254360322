<template>
  <div class="row">
    <h1>Configuración</h1>
  </div>
</template>

<script>
export default {
  name: 'configuracion-page',
  data() {
    return {};
  }
};
</script>

<style>
</style>
