<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h1>Precios de Energía</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <button class="btn" @click="descargarArchivo">Descargar plantilla</button>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h1>Factores de Degradación</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <card>
          <h2>Subir Archivo</h2>
          <form role="form" @submit.prevent="subirArchivoDegradacion($event)">
            <base-input 
              type="file"
              id="file"
              ref="file" 
              multiple="false"
              @change="handleFileUploadDegradacion($event)"
            >
            </base-input>
            
            <button type="submit" class="btn">
              Subir Archivo
            </button>
          </form>
        </card>
      </div>
      <div class="col-6">
        <card>
          <h2>Descargar Plantilla</h2>

          <button class="btn" @click="descargarArchivoDegradacion">Descargar plantilla</button>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import dayjs from 'dayjs';
import { BaseTable } from "@/components";
import Modal from '@/components/Modal';
import Multiselect from 'vue-multiselect';

// var backend_db_instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_DB_URL,
// });

Vue.component('multiselect', Multiselect);

export default {
  name: 'precios-page',
  components: {
    BaseTable,
    Modal,
    Multiselect,
  },
  computed: {
  },
  data() {
    return {
      archivoDegradacion: null,
    };
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    async descargarArchivo(){
      try {
        let actual = dayjs().format('YYYY-MM-DD HH:mm:ss');

        let res = await this.$http({
          method: 'GET',
          url: '/api/precios/archivo',
          responseType: 'blob'
        });
        // let filename = res.headers['content-disposition'].split('filename=')[1].split('.')[0];
        // let extension = res.headers['content-disposition'].split('.')[1].split(';')[0];

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        // fileLink.setAttribute('download', `${filename}.${extension}`);
        fileLink.setAttribute('download', `Template Precios - ${actual}.xlsx`);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo descargado', 'success');

      } catch (error) {
        console.log(error);
        this.notifyVue('Error al pedir archivo', 'danger');
      }
    },

    async descargarArchivoDegradacion(){
      try {
        let actual = dayjs().format('YYYY-MM-DD HH:mm:ss');

        let res = await this.$http({
          method: 'GET',
          url: '/api/degradacion/archivo',
          responseType: 'blob'
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Template Degradacion - ${actual}.xlsx`);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo descargado', 'success');

      } catch (error) {
        console.log(error);
        this.notifyVue('Error al pedir archivo', 'danger');
      }
    },

    handleFileUploadDegradacion(e){
      this.archivoDegradacion = e.target.files[0];
    },

    async subirArchivoDegradacion(e){
      // e.preventDefault();
      if(this.archivoDegradacion==null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      let formData = new FormData();
      formData.append('file', this.archivoDegradacion);

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/degradacion/subir',
          data: formData,
          // responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.notifyVue('Archivo subido con éxito', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    }
  },
  async mounted(){
  }
};
</script>

<style>
</style>
