<template>
  <div>
    <h1>Tarifas</h1>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Ver tarifas</h2>

          <div class="row">
            <div class="col-md-2">
              <label class="control-label">
                Seleccionar Fecha*
              </label>
              <br>
              <date-picker 
                class="datepicker"
                v-model="item.fecha"
                valueType="format"
                type="month"
                format="YYYY-MM"
              >
              </date-picker>
            </div>
            <div class="col-md-2">
              <base-input label="Distribuidora">
                <select class="form-control"  v-model="item.distribuidora">
                  <option v-for="distribuidora in distribuidoras" :key="distribuidora.codigo" :value="distribuidora.codigo">
                    {{distribuidora.nombre}}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Tarifa">
                <select class="form-control"  v-model="item.tarifa">
                  <option v-for="tarifa in tarifas" :key="tarifa.codigo" :value="tarifa.codigo">
                    {{tarifa.codigo}}
                  </option>
                </select>
              </base-input>
              
            </div>
            <div class="col-md-3">
              <base-input label="Comuna">
                <multiselect v-model="item.comuna" :options="comunas"
                  placeholder="Comuna" label="nombre" track-by="nombre">
                </multiselect>
              </base-input>
            </div>
            <div class="col-md-3">
              <br>
              <button class="btn" @click="fetchDatos">
                Pedir datos
              </button>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12">
              <DataTable
                :value="datos_tabla"
                stripedRows
                :paginator="true"
                :rows="50"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[25, 50, 100]"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords}"
              >
                <Column
                  v-for="col of columns"
                  :key="col.field"
                  :field="col.field"
                  :header="col.header" 
                  sortable
                >
                </Column>
            </DataTable>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Subir Archivo de Tarifas</h2>
          <h4 class="text-danger">*Importante*</h4>
          <ul>
            <li class="text-danger">Formato de archivo: .pdf</li>
          </ul>
          <form role="form">
            <label class="typo__label">Seleccionar Distribuidora</label>
            <multiselect 
              v-model="distribuidora_seleccionada"
              :options="distribuidoras"
              track-by="codigo"
              label="nombre"
              placeholder="Seleccionar Distribuidora"
            >
            </multiselect>
            <label class="control-label">
              Seleccionar Fecha
            </label>
            <br>
            <date-picker 
              class="datepicker"
              v-model="fecha_archivo"
              valueType="format"
              type="month"
              format="YYYY-MM"
            >
            </date-picker>
            <base-input 
              label="Archivo"
              placeholder="Archivo"
              v-model="archivo_tarifas_model"
              type="file"
              id="file"
              ref="file" 
              v-on:change="handleFileUpload($event)"
            >
            </base-input>

            <button class="btn" @click="subirArchivoDistribuidora($event)">
              Subir Archivo
            </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2 class="text-danger">Eliminar tarifas</h2>

          <div class="row">
            <div class="col-md-2">
              <label class="control-label">
                Seleccionar Fecha*
              </label>
              <br>
              <date-picker 
                class="datepicker"
                v-model="item_eliminar.fecha"
                valueType="format"
                type="month"
                format="YYYY-MM"
              >
              </date-picker>
            </div>
            <div class="col-md-2">
              <base-input label="Distribuidora">
                <select class="form-control"  v-model="item_eliminar.distribuidora">
                  <option v-for="distribuidora in distribuidoras" :key="distribuidora.codigo" :value="distribuidora.codigo">
                    {{distribuidora.nombre}}
                  </option>
                </select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Tarifa">
                <select class="form-control"  v-model="item_eliminar.tarifa">
                  <option v-for="tarifa in tarifas" :key="tarifa.codigo" :value="tarifa.codigo">
                    {{tarifa.codigo}}
                  </option>
                </select>
              </base-input>
              
            </div>
            <div class="col-md-3">
              <base-input label="Comuna">
                <multiselect v-model="item_eliminar.comuna" :options="comunas"
                  placeholder="Comuna" label="nombre" track-by="nombre">
                </multiselect>
              </base-input>
            </div>
            <div class="col-md-3">
              <br>
              <button class="btn btn-danger" @click="eliminarDatos">
                Eliminar Datos
              </button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

Vue.component('multiselect', Multiselect);

export default {
  name: 'tarifa-page',
  components: {
    Multiselect,
    DatePicker,
    DataTable,
    Column
  },
  data(){
    return {
      distribuidoras: [
        {nombre: 'CGE', codigo: 'CGE'},
        {nombre: 'Chilquinta', codigo: 'CHILQUINTA'},
        {nombre: 'Energía Casa Blanca', codigo: 'ENERGIACASABLANCA'},
        {nombre: 'Luz Linares', codigo: 'LUZLINARES'},
        {nombre: 'Luz Parral', codigo: 'LUZPARRAL'},
        {nombre: 'Enel', codigo: 'ENEL'},
        {nombre: 'CEC', codigo: 'CEC'},
      ],
      distribuidora_seleccionada: null,
      archivo_tarifas: null,
      archivo_tarifas_model: null,
      fecha_archivo: null,
      distribuidoras: [],
      comunas: [],
      tarifas: [],
      item: {
        fecha: null,
        distribuidora: 'TODAS',
        comuna: {codigo: 'TODAS', nombre: 'Todas'},
        tarifa: 'TODAS'
      },
      // tabla de tarifas
      columns: [
        { field: "ano", header: "Año"},
        { field: "mes", header: "Mes"},
        { field: "distribuidora", header: "Distrib."},
        { field: "tarifa", header: "Tarifa" },
        { field: "comuna", header: "Comuna" },
        { field: "cargo_energia", header: "Uso de Energía" },
        { field: "cargo_sistema", header: "Uso de Sistema (Transporte)" },
        { field: "cargo_servicio", header: "Uso de servicio publico" }
      ],
      datos_tabla: [],
      item_eliminar: {
        fecha: null,
        distribuidora: 'TODAS',
        comuna: {codigo: 'TODAS', nombre: 'Todas'},
        tarifa: 'TODAS'
      },
    }
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    async fetchDatos(){
      let datos = this.item;

      // revisar eleccion de fecha
      if(datos.fecha===null){
        this.notifyVue('No se ha escogido una fecha', 'danger');
        return;
      }

      let ano = datos.fecha.split('-')[0];
      let mes = datos.fecha.split('-')[1];

      try {
        let res = await this.$http.get('/api/tarifas/',
        {
          params: {
            ano,
            mes,
            distribuidora: datos.distribuidora,
            comuna: datos.comuna.codigo,
            tarifa: datos.tarifa
          }
        });

        let data = res.data;
        this.datos_tabla = data;

        this.notifyVue(data.length + ' datos encontrados', 'success');
        
      } catch (error) {
        console.error(error)
      }
    },

    async eliminarDatos(){
      let datos = this.item_eliminar;

      // revisar eleccion de fecha
      if(datos.fecha===null){
        this.notifyVue('No se ha escogido una fecha', 'success');
        return;
      }

      let ano = datos.fecha.split('-')[0];
      let mes = datos.fecha.split('-')[1];

      try {
        let res = await this.$http.delete('/api/tarifas/',
        {
          data: {
            ano,
            mes,
            distribuidora: datos.distribuidora,
            comuna: datos.comuna.codigo,
            tarifa: datos.tarifa
          }
        });

        let data = res.data;

        this.notifyVue(data.n + ' datos eliminados', 'danger');
        
      } catch (error) {
        console.error(error)
      }
    },
    
    handleFileUpload(e){
      this.archivo_tarifas = e.target.files[0];
      console.log('Archivo de tarifas cambiado');
    },

    async subirArchivoDistribuidora(e) {
      e.preventDefault();

      // revisar eleccion de distribuidora
      if(this.distribuidora_seleccionada === null){
        this.notifyVue('No se ha escogido una distribuidora', 'danger');
        return;
      }

      // revisar eleccion de fecha
      if(this.fecha_archivo===null){
        this.notifyVue('No se ha escogido una fecha', 'danger');
        return;
      }

      // revisar existencia de archivo
      if(this.archivo_tarifas === null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      // revisar formato
      let formato = this.archivo_tarifas.name.split('.').pop()
      if(formato !== 'pdf'){
        this.notifyVue('Formato del archivo debe ser PDF', 'danger');
        return;
      }

      let formData = new FormData();
      let ano = this.fecha_archivo.split('-')[0];
      let mes = this.fecha_archivo.split('-')[1];
      formData.append('file', this.archivo_tarifas);
      formData.append('distribuidora', this.distribuidora_seleccionada.codigo);
      formData.append('ano', ano);
      formData.append('mes', mes);
      // agregar fecha

      try {
        this.notifyVue('Procesando archivo', 'info');
        let res = await this.$http({
          method: 'POST',
          url: '/api/tarifas/archivo',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        let creados = res.data.creados;
        let errores = res.data.errores;

        this.notifyVue(creados+' datos de distribuidora guardados', 'success');
        this.notifyVue(errores+' datos de distribuidora con error', 'warning');
      } catch (error) {
        this.notifyVue('Error al procesar el archivo', 'danger');
        console.error(error);
      }
    },

    pedirDatosDistribuidoras(){

    }
  },

  created(){
    // pedir listado de distribuidoras
    this.$http({
      method: 'GET',
      url: '/api/distribuidoras/min',
    })
    .then(res => {
      console.log('Distribuidoras obtenidas');
      this.distribuidoras = [
        {codigo: 'TODAS', nombre: 'Todas'},
        ...res.data
      ];
    })
    .catch(e => {
      console.error(e);
    });

    // pedir listado de comunas
    this.$http({
      method: 'GET',
      url: '/api/comunas/min',
    })
    .then(res => {
      console.log('Comunas obtenidas');
      this.comunas = [
        {codigo: 'TODAS', nombre: 'Todas'},
        ...res.data
      ];
    })
    .catch(e => {
      console.error(e);
    });

    // pedir listado de tarifas
    this.$http({
      method: 'GET',
      url: '/api/tarifas/min',
    })
    .then(res => {
      console.log('Tarifas obtenidas');
      this.tarifas = [
        {codigo: 'TODAS'},
        ...res.data
      ];
    })
    .catch(e => {
      console.error(e);
    });
  }
}
</script>