import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import reset from "./modules/reset";
import plants from "./modules/plants-module";
import report from "./modules/report-module";
import grupos from "./modules/portafolios-module";
import invitados from "./modules/invitados-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    reset,
    plants,
    report,
    grupos,
    invitados,
  }
});
