<template>
  <div>
    <div class="container">
      <div class="header-body text-center">
        <h1>Usuarios</h1>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12">
          <card>
            <router-link
              :to="{ name: 'usuarios-crear'}"
            >
              <button class="btn">
                Agregar Usuario
              </button>
            </router-link>
          </card>
        </div>

        <div class="col-12">
          <modal 
            :show="mostrarModalEliminar"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-lg"
          >
            <template slot="header">
              <h1>Adventencia!</h1>
            </template>
            <card 
              type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0"
            >
              <template>
                <h2>Se eliminarán todos los datos del usuario seleccionado</h2>
                <h3 v-if="usuarioEliminar">{{usuarioEliminar.email}}</h3>
              </template>
            </card>
            <template slot="footer">
              <base-button type="primary" @click="eliminarUsuario(usuarioEliminar)">
                Aceptar
              </base-button>
              <base-button type="secondary" class="ml-auto" @click="cerrarModalEliminar">
                Cancelar
              </base-button>
            </template>
          </modal>

          <card>
            <div class="table-responsive">
              <table class="table tablesorter table-hover">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Apellido</th>
                    <th>E-mail</th>
                    <th>Rol</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in usuarios" :key="index">
                  <td>
                    {{item.first_name}}
                  </td>
                  <td>
                    {{item.last_name}}
                  </td>
                  <td>
                    {{item.email}}
                  </td>
                  <td>
                    {{item.groups[0].name}}
                  </td>
                  <td>
                    <button class="btn btn-success mr-2 ">
                      <router-link
                        :to="{ name: 'usuario-asignar-plantas', params: { id: item.id }}"
                        class="editar-usuario"
                      >
                        Asignar Plantas
                      </router-link>
                    </button>
                    <button class="btn btn-info mr-2 ">
                      <router-link
                        :to="{ name: 'usuario', params: { id: item.id }}"
                        class="editar-usuario"
                      >
                        Editar
                      </router-link>
                    </button>
                    <button class="btn btn-danger" @click="abrirModalEliminar(item)">
                      Eliminar
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  name: 'usuarios',
  components: {
    Modal,
  },
  data () {
    return {
      usuarios: [],
      usuarioEliminar: null,
      mostrarModalEliminar: false,
    }
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    abrirModalEliminar(usuario){
      this.usuarioEliminar = usuario;
      this.mostrarModalEliminar = true;
    },

    cerrarModalEliminar(){
      this.plantaEliminar = null;
      this.mostrarModalEliminar = false;
    },

    async eliminarUsuario(usuario){
      try {
        let res = await this.$http({
          method: 'DELETE',
          url: '/api/usuarios/'+usuario.id
        });

        console.log('Eliminada');
        this.usuarios = this.usuarios.filter(item => item.id != usuario.id);
        this.usuarioEliminar = null;
        this.mostrarModalEliminar = false;

        this.notifyVue('Se ha eliminado el usuario', 'success');
      } catch (error) {
        this.notifyVue('No se ha podido eliminar el usuario', 'danger');
        console.log(error);
      }
    }
  },
  async mounted(){
    try {
      let res = await this.$http({
        method: 'GET',
        url: '/api/usuarios'
      });

      this.usuarios = res.data;

    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss" scoped>
.editar-usuario {
  color: #fff;
  font-weight: 600;
}
</style>>
