<template>
  <div>
    <h1>Reportes: {{planta.nombre_visible}}</h1>

    <div class="row">
      <div class="col-12">
        <card v-if="n_reportes > 0">
          <div v-for="ano in sortedYears" :key="ano">
            <h2>{{ano}}</h2>
            <div class="table-responsive">
              <table class="table tablesorter table-hover">
                <thead>
                  <tr>
                    <th>Mes</th>
                    <th>Día Inicio</th>
                    <th>Día Fin</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="reporte in reportesAgrupados[ano]" :key="reporte.id">
                    <td>
                      {{reporte.fecha_inicio | getMesAno}}
                    </td>
                    <td>
                      {{reporte.fecha_inicio | getDia}}
                    </td>
                    <td>
                      {{reporte.fecha_fin | getDia}}
                    </td>

                    <td>
                      <router-link v-if="reporte.tiene_reporte"
                        :to="{ name: 'reporte-pdf-visita', params: {id_reporte: reporte.id}}"
                      >
                        <button class="btn btn-warning">Reporte</button>
                      </router-link>
                      <div v-else>
                        Reporte en proceso de creación
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </card>
        <card v-else>
          <h1>Esta planta no tiene reportes</h1>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
var localeData = require('dayjs/plugin/localeData');
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween)
require('dayjs/locale/es');
dayjs.locale('es');

export default {
  name: 'visita-reportes-page',
  components: {
    BaseTable,
  },
  computed: {
    sortedYears(){
      return Object.keys(this.reportesAgrupados).sort().reverse();
    }
  },
  filters: {
    getMesAno(value){
      return dayjs(value, 'YYYY-MM-DD').format('MMMM YYYY');
    },

    getMes(value){
      return dayjs(value, 'YYYY-MM-DD').format('MMMM');
    },

    getDia(value){
      return dayjs(value, 'YYYY-MM-DD').date();
    },
  },
  data() {
    return {
      plantas: [],
      reportesAgrupados: {},
      planta: {},
      n_reportes: 0,
    };
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    getYear(fecha){
      return dayjs(fecha, 'YYYY-MM-DD').year();
    },

    groupByYear(listado){
      return listado.reduce((grupos, item) => {
        let year = this.getYear(item.fecha_inicio);
        let grupo = (grupos[year] || []);

        grupo.push(item);

        grupos[year] = grupo;
        return grupos;
      }, {});
    }
  },
  async mounted(){
    let id = this.$route.params.id;

    try {
      let res = await this.$http({
        method: 'GET',
        url: '/api/invitado/planta/'+id,
      });

      let planta = res.data;
      this.n_reportes = planta.reportes.length;
      this.reportesAgrupados = this.groupByYear(planta.reportes);
      this.planta = planta;

    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style>
</style>
