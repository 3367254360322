<template>
  <footer class="footer no-print">
    <div class="container-fluid">
      <div class="copyright">
        <i class="fa fa-copyright" style="font-weight: 200;"></i> {{year}} 
        <a href="https://www.deltactivos.com" target="_blank" rel="noopener">DeltActivos</a>.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
