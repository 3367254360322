<template>
  <div>
    <h1>Plantas:</h1>

    <div class="row">
      <div class="col-12">
        <card>
          <div class="table-responsive">
            <table class="table tablesorter table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(planta, index) in plantas" :key="index">
                  <td>
                    <router-link
                      :to="{ name: 'reportes-planta-visita', params: { id: planta.id }}"
                    >
                      {{planta.nombre_visible}}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";

export default {
  name: 'visita-page',
  components: {
    BaseTable,
  },
  computed: {
  },
  data() {
    return {
      plantas: [],
      plantaEliminar: null,
      mostrarModalEliminar: false,
    };
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },
  },
  async mounted(){

    try {
      let res = await this.$http({
        method: 'GET',
        url: '/api/invitado/plantas',
        // params: {
        //   portafolio: this.$route.query.portafolio,
        // }
      });

      this.plantas = res.data;

    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style>
</style>
