<template>
  <div>
    <div v-if="loaded">
      <!-- Modal para editar datos del sensor -->
      <modal 
        :show.sync="datosModalSensorEditar.show"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-lg"
      >
        <card 
          type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template>
            <div class="text-center text-muted mb-4">
              <small>Editar Sensor</small>
            </div>
            <form role="form">
              <div class="row">
                <div class="col-md-4">
                  <base-input
                    label="Nombre"
                    placeholder="Nombre"
                    v-model="dataSensor.nombre"
                  >
                  </base-input>
                </div>
                <div class="col-md-4">
                  <base-input label="Periodo de Datos">
                    <select class="form-control" id="periodo" v-model="dataSensor.periodo">
                      <option v-for="periodo in periodos" :key="periodo.key" :value="periodo.key">{{periodo.label}}</option>
                    </select>
                  </base-input>
                </div>
                <div class="col-md-4">
                  <base-input label="Tipo de Sensor">
                    <select class="form-control" id="tipo" v-model="dataSensor.tipo">
                      <option v-for="tipo in tipos" :key="tipo.key" :value="tipo.key">{{tipo.label}}</option>
                    </select>
                  </base-input>
                </div>
              </div>
            </form>
          </template>  
        </card>
        <template slot="footer">
          <base-button type="primary" @click="editarSensor">
            Guardar Cambios
          </base-button>
          <base-button type="secondary" class="ml-auto" @click="cerrarModalSensorEditar">
            Cancelar
          </base-button>
        </template>
      </modal>

      <!-- Modal de advertencia por carga de datos -->
      <modal 
        :show="showWarningModal"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-lg"
      >
        <template slot="header">
          <h1>Advertencia!</h1>
        </template>
        <card 
          type="secondary"
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          class="border-0 mb-0"
        >
          <template>
            <h2>Esta acción realizará la carga de datos de <b>{{diasWarning}}</b> días </h2>
            <h3>¿Desea continuar con la carga de datos?</h3>
          </template>
        </card>
        <template slot="footer">
          <base-button type="primary" @click="llamarApiRadiacion">
            Aceptar
          </base-button>
          <base-button type="secondary" class="ml-auto" @click="cancelarCargaDatos">
            Cancelar
          </base-button>
        </template>
      </modal>

      <h1>{{sensor.nombre}}</h1>

      <!-- Informacion general -->
      <div class="row">
        <div class="col-12">
          <card>
            <h2>Información General</h2>

            <div class="row">
              <div class="col-4">
                Tipo: {{sensor.tipo}}
              </div>
              <div class="col-4">
                Periodo: {{sensor.periodo}}
              </div>
              <div class="col-4" v-if="sensor.planta.id">
                Planta: 
                <router-link
                  :to="{ name: 'planta', params: { id: sensor.planta.id }}"
                >
                  {{sensor.planta.nombre}}
                </router-link>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="btn btn-info" @click="abrirModalSensorEditar">
                  Editar
                </button>
              </div>
            </div>
          </card>
        </div>
      </div>


      <!-- Descargar Datos -->
      <div class="row">
        <div class="col-12">
          <card>
            <h2>Descargar datos de radiacion por rangos</h2>

            <div class="row">
              <div class="col-12 col-md-8">
                <label class="control-label">
                  Seleccionar Fechas
                </label>
                <br>
                <date-picker 
                  class="datepicker"
                  v-model="fechaDescargaRadiacion"
                  valueType="format"
                  range
                >
                </date-picker>
              </div>
              <div class="col-12 col-md-4">
                <br>
                <button class="btn" @click="descargarArchivoRadiacion">Descargar datos</button>
              </div>
            </div>
          </card>
        </div>
      </div>
      

      <!-- Obtener datos -->
      <div v-if="sensor.tipo != 'EXTERNO'" class="row">
        <div class="col-12">
          <card>
            <h2>Obtener datos</h2>

            <div class="row">
              <div class="col-8">
                <label class="control-label">
                  Fecha de Inicio
                </label>
                <br>
                <date-picker 
                  class="datepicker"
                  v-model="obtener_irradiacion"
                  valueType="format"
                  range
                >
                </date-picker>
              </div>
              <div class="col-4">
                <br>
                <button class="btn" @click="obtenerDatosRango">Obtener datos</button>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div v-show="inGroup('administrador') || inGroup('superadmin')"  class="row">
        <div class="col-12">
          <card>
            <h2>Subir Archivo de Irradiación</h2>
            <h4 class="text-danger">*Importante*</h4>
            <ul>
              <li class="text-danger">Formato de archivo .xlsx</li>
              <li class="text-danger">Primera columna de Fecha</li>
              <li class="text-danger">No incluir fila de unidades</li>
              <li class="text-danger">Solo deben venir columnas de Fecha y Radiación</li>
              <li class="text-danger">Radiación en W/m2</li>
            </ul>
            <label class="typo__label">Seleccionar Origen y Formato de Fechas</label>
            <multiselect 
              v-model="selectedFormatoIrradiacion"
              :options="opcionesFecha"
              track-by="tipo"
              label="texto"
              placeholder="Seleccionar Origen y Formato de Fecha"
            >
            </multiselect>

            <form role="form">
              <base-input 
                label="Archivo Irradiación"
                placeholder="Archivo Irradiación"
                v-model="archivoIrradiacionModel"
                type="file"
                id="file_radiacion"
                ref="file_radiacion" 
                v-on:change="handleIrradiationFileUpload($event)"
              >
              </base-input>

              <button class="btn" @click="subirArchivoIrradiacion($event)">
                Subir Archivo
              </button>
            </form>
          </card>
        </div>

        <div class="col-12">
          <card>
            <h2 class="text-danger">Eliminar datos de Radiación</h2>
            <label class="control-label">
              Rango de Fechas
            </label>
            <br>
            <date-picker 
              class="datepicker"
              range
              v-model="fechasEliminarRadiacion"
              valueType="format"
            >
            </date-picker>
            <button class="btn mt-2" @click="eliminarDatosIrradiacion()">
              Eliminar Datos
            </button>
          </card>
        </div>
      </div>

    </div>

    <div v-else>
      <h1>Cargando datos...</h1>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { BaseTable } from "@/components";
import VGrid from "@revolist/vue-datagrid";
import Multiselect from 'vue-multiselect';
import Modal from '@/components/Modal';
import DatePicker from 'vue2-datepicker';

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
var localeData = require('dayjs/plugin/localeData');
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween)
require('dayjs/locale/es');
dayjs.locale('es');

Vue.component('multiselect', Multiselect);

export default {
  name: 'sensor-page',
  components: {
    BaseTable,
    VGrid,
    Multiselect,
    Modal,
    DatePicker,
  },
  computed: {
  },
  data() {
    return {
      opcionesFecha: [
        {tipo: 'FRONIUS', texto: '(Fronius) Dia.Mes.Año Hora:Minuto'},
        {tipo: 'HUAWEI', texto: '(Huawei) Año-Mes-Dia Hora:Minuto:Segundo'},
        {tipo: 'AGROMET', texto: '(Agromet) Dia-Mes-Año Hora:Minuto'},
        {tipo: 'AGROMETEOROLOGIA', texto: '(Agrometeorologia) Dia-Mes-Año Hora:Minuto'},

      ],
      loaded: false,
      usuario: {},
      sensor: null,
      archivoIrradiacionModel: null,
      selectedFormatoIrradiacion: null,
      obtener_irradiacion: [null, null],
      datosModalSensorEditar: {
        show: false,
      },
      dataSensor: {
        nombre: ''
      },
      periodos: [
        {key: '5MIN' ,label: '5 min'},
        {key: '15MIN' ,label: '15 min'},
        {key: '30MIN' ,label: '30 min'},
        {key: '1H' ,label: '1 hora'},
      ],
      tipos: [
        { key: 'FRONIUS',label: 'Fronius'},
        { key: 'SMA',label: 'SMA'},
        { key: 'HUAWEI',label: 'Huawei'},
        { key: 'HUAWEIPMGD',label: 'Huawei PMGD'},
        { key: 'HUAWEIPT',label: 'Huawei Portugal'},
        { key: 'SOLARGIS',label: 'Solargis'},
        { key: 'EXTERNO',label: 'Externo'},
        { key: 'SUNGROW',label: 'Sungrow'},
      ],
      fechasEliminarRadiacion: [null, null],
      // Modal de advertencia
      showWarningModal: false,
      diasWarning: 0,
      // Descarga de datos
      fechaDescargaRadiacion: null
    };
  },
  methods: {
    inGroup(grupo){
      return this.usuario.groups.some(group => group.name == grupo)
    },

    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    cerrarModalPlantaEditar(){
      this.datosModalSensorEditar = {
        show: false,
        // fecha: null,
        // dolar: null,
        // sensor: null
      }
    },

    async editarPlanta(){
      // Validar datos

      let planta = this.dataSensor;
      // this.datosModalReporteEditar.sensor_seleccionado = this.datosModalReporteEditar.sensor.id

      try {
        let res = await this.$http({
          method: 'PUT',
          url: '/api/plantas/'+planta.id,
          data: planta
        });

        let planta_cambiada = res.data;
        this.planta = planta_cambiada;

        // this.reportes = this.reportes.map((item) => {
        //   if(item.id == reporte_cambiado.id){
        //     return reporte_cambiado;
        //   }
        //   return item;
        // })

        this.notifyVue('Datos de Planta guardados', 'success');
        this.datosModalSensorEditar = {
          show: false,
        }
      } catch (error) {
        this.notifyVue('Error al guardar', 'danger');
        console.log(error);
      }
      
    },

    abrirModalReporteEditar(reporte){
      this.datosModalReporteEditar = {...reporte};

      if(reporte.sensor_seleccionado!=null){
        let sensor = this.sensores.find(sensor => sensor.id == reporte.sensor_seleccionado);
        this.datosModalReporteEditar.sensor = sensor;
      }
      
      this.datosModalReporteEditar.show = true;
    },

    cerrarModalReporteEditar(){
      this.datosModalReporteEditar = {
        show: false,
        fecha: null,
        dolar: null,
        codigo: null,
        sensor: null
      }
    },

    async llamarApiRadiacion(){
      let sensor_id = this.sensor.id;
      let datos = this.obtener_irradiacion;

      let fecha_inicio = dayjs(datos[0]).startOf('day');
      let fecha_fin = dayjs(datos[1]).endOf('day');

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/sensores/'+sensor_id+'/irradiacion/rangos',
          data: {
            fecha_inicio: fecha_inicio.unix(),
            fecha_fin: fecha_fin.unix(),
          }
        });

        this.notifyVue('Obteniendo datos. La operación tardará unos minutos.\nEl resultado será informado al correo del usuario.', 'success');
      } catch (error) {
        this.notifyVue('Error al pedir datos', 'danger');
        console.error(error);
      }

      this.showWarningModal = false;
    },

    cancelarCargaDatos(){
      this.showWarningModal = false;
    },

    obtenerDatosRango(){
      let datos = this.obtener_irradiacion;

      if(datos[0]===null || datos[1]===null){
        this.notifyVue('Fechas no pueden quedar vacías', 'danger');
        return;
      }

      let fecha_inicio = dayjs(datos.fecha_inicio).startOf('day');
      let fecha_fin = dayjs(datos.fecha_fin).endOf('day');

      if(fecha_fin.isBefore(fecha_inicio)){
        this.notifyVue('Fecha de inicio debe ser anterior a fecha de fin', 'danger');
        return;
      }

      if(fecha_fin.diff(fecha_inicio, 'day') >= 30){
        this.diasWarning = fecha_fin.diff(fecha_inicio, 'day');
        this.showWarningModal = true;
        return;
      }

      this.llamarApiRadiacion();
    },

    handleIrradiationFileUpload(e){
      this.archivoIrradiacion = e.target.files[0];
      console.log('Archivo de irradiacion cambiado');
    },

    async subirArchivoIrradiacion(e){
      e.preventDefault();

      if(this.selectedFormatoIrradiacion==null){
        this.notifyVue('No se ha escogido un formato de Fecha', 'danger');
        return;
      }

      if(this.archivoIrradiacion===null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      let sensor = this.sensor;
      let formData = new FormData();
      formData.append('file', this.archivoIrradiacion);
      formData.append('sensor_id', sensor.id);
      formData.append('formato', this.selectedFormatoIrradiacion.tipo);

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/irradiacion/archivo',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.notifyVue('Datos de Irradiacion guardados', 'success');
      } catch (error) {
        this.notifyVue('Error al guardar el archivo', 'danger');
        console.log(error);
      }
    },

    abrirModalSensorEditar(){
      this.dataSensor = {...this.sensor}

      // this.dataSensor = this.planta;
      this.datosModalSensorEditar.show = true;
    },

    cerrarModalSensorEditar(){
      this.datosModalSensorEditar = {
        show: false,
        // fecha: null,
        // dolar: null,
        // sensor: null
      }
    },

    async editarSensor(){
      // Validar datos
      let sensor = this.dataSensor;
      try {
        let res = await this.$http({
          method: 'PUT',
          url: '/api/sensores/'+sensor.id,
          data: sensor
        });

        let sensor_cambiado = res.data;
        this.sensor = sensor_cambiado;

        // this.reportes = this.reportes.map((item) => {
        //   if(item.id == reporte_cambiado.id){
        //     return reporte_cambiado;
        //   }
        //   return item;
        // })

        this.notifyVue('Datos del Sensor guardados', 'success');
        this.datosModalSensorEditar.show = false;
      } catch (error) {
        this.notifyVue('Error al guardar', 'danger');
        console.log(error);
      }
      
    },

    async eliminarDatosIrradiacion(){
      let fecha_inicio = this.fechasEliminarRadiacion[0];
      let fecha_fin = this.fechasEliminarRadiacion[1];

      if(fecha_inicio==null || fecha_fin==null){
        this.notifyVue('Se debe escoger un rango de fechas', 'warning');
        return;
      }

      fecha_inicio = dayjs(fecha_inicio).startOf('day');
      fecha_fin = dayjs(fecha_fin).endOf('day');

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/irradiacion/eliminar',
          data: {
            sensor: this.sensor.id,
            fecha_inicio: fecha_inicio,
            fecha_fin: fecha_fin,
          }
        });

        let n = res.data.n;
        this.notifyVue(n+' datos eliminados.', 'success');
      } catch (error) {
        console.log(error);
      }
    },

    async descargarArchivoRadiacion(){

      let datos = this.fechaDescargaRadiacion;
      if(datos === null || datos[0]===null || datos[1]===null){
        this.notifyVue('Fechas no pueden quedar vacías', 'danger');
        return;
      }

      let sensor_id = this.sensor.id;
      let fecha_inicio = dayjs(datos[0]).startOf('day');
      let fecha_fin = dayjs(datos[1]).endOf('day');

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/irradiacion/descarga',
          data: {
            fecha_inicio: fecha_inicio.unix(),
            fecha_fin: fecha_fin.unix(),
            sensor: sensor_id
          },
          responseType: 'blob',
          // headers: {
          //   'Content-Type': 'multipart/form-data',
          // },
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        let filename = `${this.sensor.nombre} - ${fecha_inicio.format('YYYY-MM-DD')} - ${fecha_fin.format('YYYY-MM-DD')}.xlsx`;
        // fileLink.setAttribute('download', `${filename}.${extension}`);
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();

        this.notifyVue('Archivo descargado', 'success');


        // this.notifyVue('Correo enviado', 'success');
      } catch (error) {
        this.notifyVue('Error al descargar archivo', 'danger');
        console.error(error);
      }
      },
  },
  async mounted(){
    let id = this.$route.params.id;

    try {
      let user_res = await this.$http({
        method: 'GET',
        url: '/api/usuario'
      });

      this.usuario = user_res.data;

      let res = await this.$http({
        method: 'GET',
        url: '/api/sensores/'+id
      });

      this.sensor = res.data;
      this.loaded = true;
    } catch (error) {
      this.notifyVue('No se han podido cargar los datos del sensor', 'danger');
      console.log(error);
    }
  }
};
</script>

<style lang="scss" scoped>
revo-grid {
  height: 100%;
}

.tablaGrid{
  border: solid 1px rgb(37, 24, 24);
  /* border-radius: 10px; */
  /* margin-bottom: 20px; */
  width: 100%;
  height: 60vh;
  margin: 3vh 0;
  z-index: 0;
}
</style>
