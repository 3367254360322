import Vue from 'vue';
import store from '../store'
import Router from 'vue-router';

import DashboardLayout from '../layout/starter/SampleLayout.vue';
import PublicLayout from '../layout/starter/PublicLayout.vue';
import Starter from '../layout/starter/SamplePage.vue';
import DashboardPortafolios from '../layout/starter/DashboardPortafolios.vue';
import Plantas from '../layout/starter/PlantasPage.vue';
const Planta = () => import('../layout/starter/PlantaPage.vue');
import PlantaCreate from '../layout/starter/PlantaCreatePage.vue';
import PlantaCreateHuawei from '../layout/starter/PlantaCreateHuaweiPage.vue';
import PlantaCreateSma from '../layout/starter/PlantaCreateSmaPage.vue';
import PlantaCreateIngeteam from '../layout/starter/PlantaCreateIngeteamPage.vue';
import PlantaCreateFusionsolar from '../layout/starter/plantas/PlantaCreateFusionsolarPage.vue';
import Precios from '../layout/starter/PreciosPage.vue';
import Sensores from '../layout/starter/SensoresPage.vue';
import Sensor from '../layout/starter/SensorPage.vue';

import Reporte from '../layout/starter/ReportePage.vue';
import ReportePmgd from '../layout/starter/ReportePmgdPage.vue';
// const ReportePmrte = () => import('@/pages/ReportePmrtePage.vue');
import ReporteDiego from '../layout/starter/ReporteDiego.vue';
import ReportePDF from '../layout/starter/ReportePDF2.vue';
import ReportePDFPublic from '../layout/starter/ReportePDFPublic.vue';
import Configuracion from '../layout/starter/ConfiguracionPage.vue';

// Usuarios
import Usuarios from '../layout/starter/usuarios/Usuarios.vue';
import Usuario from '../layout/starter/usuarios/Usuario.vue';
import CrearUsuario from '../layout/starter/usuarios/CrearUsuario.vue';

// PMGD
import MainPmgd from '../layout/starter/pmgd/MainPmgd.vue';

// Tarifas
import TarifaPage from '../layout/tarifas/TarifaPage.vue';

// Asignador Temporal
import AsignadorPage from '../layout/tarifas/AsignadorPage.vue';

// Visitas
import VisitaLayout from '../layout/starter/VisitaLayout.vue';
import VisitaPage from '../layout/starter/VisitaPage.vue';
import VisitaReportesPage from '../layout/starter/VisitaReportesPage.vue';

import Asignacion from '../layout/starter/usuarios/Asignacion.vue';

Vue.use(Router);

//import middleware
// import auth from "@/middleware/auth";
// import guest from "@/middleware/guest";

import AuthLayout from '@/layout/starter/AuthLayout.vue';
const Login = () => import('@/pages/LoginDelta.vue');

let authPages = {
  path: "/auth",
  redirect: "/login",
  component: AuthLayout,
  children: [
    {
      path: "/login",
      name: "login",
      component: Login,
      // meta: { middleware: guest }
    },
  //   {
  //     path: "/register",
  //     name: "Register",
  //     component: Register,
  //     meta: { middleware: guest }
  //   },
  //   {
  //     path: "/password/reset",
  //     name: "Password Reset",
  //     component: PasswordReset,
  //     meta: { middleware: guest }
  //   },
  //   {
  //     path: "/password/email",
  //     name: "Password Reset",
  //     component: PasswordEmail,
  //     meta: { middleware: guest }
  //   },
  ]
};

const router = new Router({
  mode: 'history',
  routes: [
    authPages,
    {
      path: '/',
      name: 'home',
      redirect: {name: 'general'},
      component: DashboardLayout,
      meta: {requiresAuth: true, onlyDelta: true},
      // meta: {requiresAuth: true},
      children: [
        {
          path: 'general',
          name: 'general',
          components: {default: DashboardPortafolios}
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          components: { default: Starter },
          // meta: { middleware: auth }
        },
        // Portafolios
        {
          path: 'portafolio/:codigo',
          name: 'portafolio',
          component: () => import('../pages/PortafolioView.vue'),
          // meta: { middleware: auth }
        },
        {
          path: 'portafolio/:codigo/dashboard/:id_reporte',
          name: 'reporte-portafolio-dashboard',
          component: () => import('@/pages/ReportePortafolio/ReportePortafolioDashboard.vue'),
          // meta: { middleware: auth }
        },
        {
          path: 'portafolio/:codigo/reporte/:id_reporte',
          name: 'portafolio-reporte-pdf',
          component: () => import('@/pages/ReportePortafolio/ReportePortafolioPdf.vue'),
          // meta: { middleware: auth }
        },
        // Plantas
        {
          path: 'plantas',
          name: 'plantas',
          components: {default: Plantas},
          // meta: { middleware: auth }
        },
        {
          path: 'plantas/create',
          name: 'plantas-create',
          components: {default: PlantaCreate},
          // meta: { middleware: auth }
        },
        {
          path: 'plantas/create-huawei',
          name: 'plantas-create-huawei',
          components: {default: PlantaCreateHuawei},
          // meta: { middleware: auth }
        },
        {
          path: 'plantas/create-sma',
          name: 'plantas-create-sma',
          components: {default: PlantaCreateSma},
          // meta: { middleware: auth }
        },
        {
          path: 'plantas/create-ingeteam',
          name: 'plantas-create-ingeteam',
          components: {default: PlantaCreateIngeteam},
          // meta: { middleware: auth }
        },
        {
          path: 'plantas/create-fusionsolar',
          name: 'plantas-create-fusionsolar',
          components: {default: PlantaCreateFusionsolar},
          // meta: { middleware: auth }
        },
        {
          path: 'planta/:id',
          name: 'planta',
          component: Planta,
          // meta: { middleware: auth }
        },
        {
          path: 'planta/:id/reporte/:id_reporte',
          name: 'reporte',
          component: Reporte,
          // meta: { middleware: auth }
        },
        {
          path: 'planta/:id/reporte-diego/:id_reporte',
          name: 'reporte-diego',
          component: ReporteDiego,
          // meta: { middleware: auth }
        },
        // {
        //   path: 'planta/:id/reporte-degradacion/:id_reporte',
        //   name: 'reporte-degradacion',
        //   component: ReporteDegradacion,
        //   // meta: { middleware: auth }
        // },
        {
          path: 'planta/:id/reporte-pmgd/:id_reporte',
          name: 'reporte-pmgd',
          component: ReportePmgd,
          // meta: { middleware: auth }
        },
        {
          path: 'planta/:id/reporte-pmrte/:id_reporte',
          name: 'reporte-pmrte',
          component: () => import('@/pages/ReportePmrtePage.vue'),
          // meta: { middleware: auth }
        },
        {
          path: 'planta/:id/reporte-chercan/:id_reporte',
          name: 'reporte-chercan',
          component: () => import('@/pages/DashboardReportes/DashboardReporteChercanPage.vue'),
          // meta: { middleware: auth }
        },
        {
          path: 'planta/:id/reporte-pdf/:id_reporte',
          name: 'reporte-pdf',
          component: ReportePDF,
          // meta: { middleware: auth }
        },
        {
          path: 'sensores',
          name: 'sensores',
          components: {default: Sensores},
          // meta: { middleware: auth },
        },
        {
          path: 'sensor/:id',
          name: 'sensor',
          component: Sensor,
          // meta: { middleware: auth }
        },
        {
          path: 'precios',
          name: 'precios',
          components: {default: Precios},
          // meta: { middleware: auth },
        },
        {
          path: 'usuarios',
          name: 'usuarios',
          component: Usuarios
        },
        {
          path: 'usuarios/crear',
          name: 'usuarios-crear',
          component: CrearUsuario
        },
        {
          path: 'usuario/:id',
          name: 'usuario',
          component: Usuario
        },
        {
          path: 'usuario/:id/asignar',
          name: 'usuario-asignar-plantas',
          component: Asignacion
        },
        {
          path: 'configuracion',
          name: 'configuracion',
          components: {default: Configuracion},
          // meta: { middleware: auth }
        },
        {
          path: '/pmgd',
          name: 'pmgd',
          components: {default: MainPmgd},
        },
        {
          path: '/tarifas',
          name: 'tarifas',
          components: {default: TarifaPage},
        },
        {
          path: '/asignador',
          name: 'asignador',
          components: {default: AsignadorPage},
        }
      ]
    },
    {
      path: '/visita',
      name: 'home-visita',
      redirect: {name: 'plantas-visita'},
      component: VisitaLayout,
      meta: {requiresAuth: true},
      children: [
        {
          path: '/visita/plantas',
          name: 'plantas-visita',
          components: {default: VisitaPage}
        },
        {
          path: '/visita/planta/:id',
          name: 'reportes-planta-visita',
          components: {default: VisitaReportesPage}
        },
        {
          path: '/visita/planta/:id/reporte-pdf/:id_reporte',
          name: 'reporte-pdf-visita',
          component: ReportePDF,
          // meta: { middleware: auth }
        },
      ]
    },
    {
      path: '/public/',
      name: 'public',
      component: PublicLayout,
      meta: {requiresAuth: false},
      children: [
        {
          path: '/public/reporte/:codigo',
          name: 'public-report',
          component: ReportePDFPublic,
          // meta: { middleware: auth }
        },
      ]
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 }
  },
});

router.beforeEach(async (to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    await store.dispatch('checkStatus');
    await store.dispatch('fetchGroups');
    
    if (store.getters.isLoggedIn) {
      if(to.matched.some(record => record.meta.onlyDelta) && store.getters.isInGroup('invitado')) {
        console.log('only delta');
        next('/visita');
        return;
      }
      next();
      return;
    }

    next('/login');
  } else {
    next()
    return;
  }
})


export default router
