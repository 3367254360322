import Vue from 'vue';

const state = {
  plantas: [],
};

const mutations = {
  SET_PLANTAS(state, plantas){
    state.plantas = plantas;
  }
};

const actions = {
  async fetchPlantas({commit}, params){
    console.log('Pidiendo del usuario invitado');
    try {
      let res = await Vue.prototype.$http({
        method: 'GET',
        url: '/api/invitado/plantas',
      });

      commit('SET_PLANTAS', res.data);
    } catch(error) {
      console.error(error)
    }
  }
};


const getters = {
  plantas: state => state.plantas,
};

const invitados = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};


export default invitados;