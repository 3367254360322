function formatNumber(number, format, locale) {
    // Determine the number of decimal places from the format string
    const decimalPlaces = (format.split('.')[1] || '').length;

    // Create a new Intl.NumberFormat object with the given locale and options
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });

    // Format the number using the formatter
    return formatter.format(number);
}


export { formatNumber };