<template>
  <div class="p-4">
    <div class="row">
      <h1 v-if="portafolio.nombre">Mapa: {{ portafolio.nombre }}</h1>
      <h1 v-else>Mapa</h1>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="google-map" ref="googleMap"></div>
      </div>
      <div class="col-6">
        <div class="row mb-2 d-flex justify-content-center">
          <h4>N° de alarmas activas: {{ n_alarmas_totales }}</h4>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <h4>Filtros ({{ n_alarmas_filtradas }})</h4>
          </div>
          <div class="col-12 d-flex justify-content-between">
            <div
              class="criticidad-box criticidad-box-baja"
              :class="{'criticidad-box-disabled': !filtros_criticidad_activos.baja}"
              @click="boxCriticidadClickeado('baja')"
            >
              Baja
            </div>
            <div
              class="criticidad-box criticidad-box-media"
              :class="{'criticidad-box-disabled': !filtros_criticidad_activos.media}"
              @click="boxCriticidadClickeado('media')"
            >
              Media
            </div>
            <div
              class="criticidad-box criticidad-box-alta"
              :class="{'criticidad-box-disabled': !filtros_criticidad_activos.alta}"
              @click="boxCriticidadClickeado('alta')"
            >
              Alta
            </div>
          </div>
          <div class="col-12 d-flex justify-content-between mt-2">
            <div v-for="(nombre, valor) in filtros_opciones" :key="`filtro_${valor}`">
              <input
                type="checkbox"
                v-model="filtros_seleccionados"
                :name="valor"
                :value="valor"
                @change="regenerarAlarmasPorPlanta"
              >
              <label class="ml-2 pt-2" :for="valor">{{ nombre }}</label>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div v-if="selectedMarker">
            <h2>
              {{ selectedMarker.title }}
              <button
                class="btn btn-info"
                @click="crearUrlCoordenadas(selectedMarker)"
              >
                <i class="fas fa-external-link-alt"></i>
              </button>
            </h2>
            

            <h3>Listado de Alarmas</h3>
            <ul v-if="selectedMarker.tipo=='fronius'">
              <li 
                class="mt-2"
                v-for="alarma in selectedMarker.alarmas"
                :key="`alarma_${alarma.id}`"
              >
                <b :class="'titulo-alarma-'+alarma.criticidad">{{alarma.codigo}} - {{alarma.error}}</b>
                {{alarma.comentario}}
              </li>
            </ul>
            <ul v-if="selectedMarker.tipo=='sma'">
              <li 
                class="mt-2"
                v-for="alarma in selectedMarker.alarmas_sma"
                :key="`alarma_sma_${alarma.id}`"
              >
                <b :class="'titulo-alarma-'+alarma.criticidad">{{alarma.tipo}} - {{alarma.aviso}}</b>
                {{alarma.comentario}}
              </li>
            </ul>
            <ul v-if="selectedMarker.tipo=='huawei'">
              <li 
                class="mt-2"
                v-for="alarma in selectedMarker.alarmas_huawei"
                :key="`alarma_huawei_${alarma.id}`"
              >
                <b :class="'titulo-alarma-'+alarma.criticidad">{{alarma.gravedad}} - {{alarma.error}}</b>
                {{alarma.comentario}}
              </li>
            </ul>
          </div>
          <div v-else>
            <h3>Haga click en un marcador para ver sus datos.</h3>

            <p v-if="error" class="text-danger">
              {{ error }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';
import axios from 'axios';

var alarmas_api_instance = axios.create({
  baseURL: process.env.VUE_APP_API_ALARMAS_URL,
  headers: {
    'Authorization': 'Bearer ' + process.env.VUE_APP_API_ALARMAS_TOKEN
  }
});

export default {
  name: 'delta-map',
  components: {
  },
  props: {
    portafolio: {
      type: Object,
      default: {nombre: '', codigo: ''},
      description: "Objeto que representa un portafolio"
    },
  },
  data(){
    return {
      error: null,
      // Objetos necesarios para el mapa de Google
      loader: null,
      map: null,
      google: null,
      // listado de marcadores
      plantas: [],
      plantas_filtro_criticidad: [],
      plantas_filtradas: [],
      // listado de marcadores agregados al mapa
      markers: [],
      googleMarkers: [],
      selectedMarker: null,
      mapConfig: {
        center: {
        lat: -33.416889,
        lng: -70.606705
        },
        zoom: 7
      },
      criticidad_baja_color: '#25bea3',
      criticidad_media_color: '#6f3199',
      criticidad_alta_color: '#ff0000',
      // datos del componente
      isOpen: false,
      mapbox: null,
      filtros_opciones: {
        'con_alarma': 'Con Alarma',
        'sin_alarma': 'Sin Alarma'
      },
      filtros_seleccionados: ['con_alarma', 'sin_alarma'],
      filtros_criticidad_seleccionados: ['baja', 'media', 'alta'],
      filtros_criticidad_activos: {
        'baja': true,
        'media': true,
        'alta': true,
      },
      // codigos de alarmas fronius que no deben mostrarse
      no_mostrar_fronius: [3, 993, 12, 212]
    }
  },
  computed: {
    n_alarmas_totales(){
      return this.plantas.reduce((acc, planta) => {
        let alarmas = planta.alarmas.length + planta.alarmas_huawei.length + planta.alarmas_sma.length;
        return acc + alarmas;
      }, 0);
    },
    n_alarmas_filtradas(){
      return this.plantas_filtradas.reduce((acc, planta) => {
        let alarmas = planta.alarmas.length + planta.alarmas_huawei.length + planta.alarmas_sma.length;
        return acc + alarmas;
      }, 0);
    }
  },
  created() {
  },
  mounted() {
    console.log('MapaPlantas mounted.');
    const apiKey = process.env.VUE_APP_GOOGLE_MAPS_KEY;
    const codigos_alarmas = this.no_mostrar_fronius;

    console.log(`Pedir alarmas ${this.portafolio.codigo_alarmas}`);
    alarmas_api_instance.get('/api/alarmas/portafolio', {
      params: {
        portafolio: this.portafolio.codigo_alarmas
      }
    })
    .then(res => {
      const plantas = res.data;
      const plantas_con_alarmas_filtradas = plantas.map(planta => {
        if(planta.tipo === 'fronius'){
          const alarmas = planta.alarmas.filter(alarma => !codigos_alarmas.includes(alarma.codigo));
          planta.alarmas = alarmas;
        }
        return planta;
      })
      return plantas_con_alarmas_filtradas;
    })
    .then(data => {
      this.plantas = data;
      this.plantas_filtradas = data;
      console.log('Datos cargados');
      this.createMarkers();
    })
    .catch(err => {
      this.error = err.response.data.data.error;
      console.log(err);
    });


    // cargar servicio de mapas de google
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places"]
    });

    const mapContainer = this.$refs.googleMap;
    this.loader = loader;

    loader.load()
    .then(()=>{
      console.log('Loader de google cargado');
      this.google = google;
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

      this.createMarkers();
    })
    .catch(err => {
      console.log(err);
    });

    
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    // Obtiene la máxima criticidad de alarmas de una
    // planta y retorna el color correspondiente
    maximaCriticidad(alarmas){
      if(alarmas.length == 0){
        return '#face63';
      }

      let criticidades = alarmas.map(alarma => alarma.criticidad);

      if(criticidades.indexOf('alta') !== -1){
        return this.criticidad_alta_color;
      }
      else if(criticidades.indexOf('media') !== -1){
        return this.criticidad_media_color;
      }
      if(criticidades.indexOf('baja') !== -1){
        return this.criticidad_baja_color;
      }

      return '#face63';
    },

    setMapOnAll(map) {
      this.googleMarkers.forEach((marcador, idx) => {
        marcador.setMap(map)
      });

      // for (let i = 0; i < this.googleMarkers.length; i++) {
      //   this.googleMarkers[i].setMap(map);
      // }
    },

    // Desenlaza todos los marcadores del mapa
    clearMarkers() {
      this.setMapOnAll(null);
    },

     // Eliminar todos los marcadores del mapa
     deleteAllMarkers(){
      this.clearMarkers();
      this.googleMarkers = [];
    },

    addMarker(newMarker){
      let main_path;
      let scale = 1.2;

      let strokeColor = '#000';
      let fillColor = newMarker.has_irradiation ? '#276259' : '#face63';
      let strokeWeight = 1.5;

      main_path = 'M0 -13 L10 7 L-10 7 Z';

      // Definir color del borde
      if(newMarker.tipo=='fronius'){
        // let newAlarmas = this.alarmasNoFiltradas(newMarker.alarmas);
        strokeColor = this.maximaCriticidad(newMarker.alarmas);
      }
      else if(newMarker.tipo=='sma'){
        strokeColor = this.maximaCriticidad(newMarker.alarmas_sma);
      }
      else if(newMarker.tipo=='huawei'){
        strokeColor = this.maximaCriticidad(newMarker.alarmas_huawei);
      }
      else if(newMarker.tipo=='oficina'){
        // strokeColor = '#668e87';
        strokeColor = this.maximaCriticidad(newMarker.alarmas_oficina);
      }
    

      let main_figure = {
        path: main_path,
        strokeColor,
        strokeWeight,
        fillColor,
        fillOpacity: 0.7,
        scale
      }

      // Al crear un marcador, aparece en el mapa
      const marker = new this.google.maps.Marker({
        position: newMarker.position,
        map: this.map,
        title: newMarker.title,
        icon: main_figure,
        data: newMarker,
      });

      this.google.maps.event.addListener(marker, "click", (event, item) => {
        // let contentString =
        // '<div id="content">' +
        // '<div id="siteNotice">' +
        // "</div>" +
        // '<h4>' + marker.title + '</h4>' +
        // '<div id="bodyContent">' +
        // '<p>Alarmas</p>' +
        // this.createAlarmaString(marker.data) +
        // '<p>Contactos</p>' + 
        // this.createContactoString(marker.data) + 
        // "</div>" +
        // "</div>";

        // let infowindow = new this.google.maps.InfoWindow({
        //   content: contentString,
        // });


        this.selectedMarker = newMarker;
        // this.infowindow.close();
        // infowindow.open(this.map, marker);
        // this.infowindow = infowindow;
      });


      // Se debe mantener un listado de marcadores
      // para cuando se necesite eliminarlos
      this.googleMarkers.push(marker);
    },

    createMarkers() {
      const plantasMarkers = this.plantas_filtradas
      .map((item) => {
        return {
          position: {
            lat: item.lat,
            lng: item.lng,
          },
          title: item.nombre,
          // region: item.region,
          has_irradiation: item.has_irradiation,
          // plantaId: item.id,
          // edit_url: item.edit_url,
          // contactos: item.contactos,
          tipo: item.tipo,
          alarmas: item.alarmas,
          alarmas_sma: item.alarmas_sma,
          alarmas_huawei: item.alarmas_huawei,
          alarmas_oficina: item.alarmas_oficina,
          // portfolio_number: item.portfolio_number.toString(),
          // tiene_maleza: item.tiene_maleza,
          // tiene_suciedad: item.tiene_suciedad
        };
      })

      this.markers = plantasMarkers;

      plantasMarkers.forEach((item) => {
        this.addMarker(item);
      })
    },

    cantidadDeAlarmas(planta, tipo=''){
      let tipo_planta = planta.tipo;

      let alarmas = [];
      if(tipo_planta=='fronius'){
        alarmas = planta.alarmas;
      }
      else if(tipo_planta=='sma'){
        alarmas = planta.alarmas_sma;
      }
      else if(tipo_planta=='huawei'){
        alarmas = planta.alarmas_huawei;
      }
      else if(tipo_planta=='oficina'){
        alarmas = planta.alarmas_oficina;
      }

      return alarmas.length;

      // if(tipo==''){
      //   return alarmas.length;
      // }
      // else if(tipo=='pendientes'){
      //   return alarmas.filter((item) => {
      //     if(item.fechaAsignada==null){
      //       return item;
      //     }
      //   }).length;
      // }
      // else if(tipo=='programadas'){
      //   return alarmas.filter((item) => {
      //     if(item.fechaAsignada!=null){
      //       return item;
      //     }
      //   }).length;
      // }
      // else if(tipo=='programadas-hoy'){
      //   return alarmas.filter((item) => {
      //     if(item.fechaAsignada!=null){
      //       if(dayjs().isSame(item.fechaAsignada, 'day'))
      //         return item;
      //     }
      //   }).length;
      // }

      // return 0;
    },

    regenerarMarcadores() {
      this.deleteAllMarkers();
      const filtros = this.filtros_seleccionados;
      const con_alarma = filtros.includes('con_alarma');
      const sin_alarma = filtros.includes('sin_alarma');


      this.plantas_filtradas = this.plantas_filtro_criticidad.filter(planta => {
        const tiene_alarmas = this.cantidadDeAlarmas(planta) > 0;

        return (tiene_alarmas && con_alarma) || (!tiene_alarmas && sin_alarma);
      });

      this.createMarkers();

    },

    regenerarAlarmasPorPlanta(){
      const listado_critcidades = this.filtros_criticidad_seleccionados;
      this.plantas_filtro_criticidad = this.plantas.map(planta => {
        const alarmas = planta.alarmas.filter(alarma => listado_critcidades.includes(alarma.criticidad));
        const alarmas_huawei = planta.alarmas_huawei.filter(alarma => listado_critcidades.includes(alarma.criticidad));
        const alarmas_sma = planta.alarmas_sma.filter(alarma => listado_critcidades.includes(alarma.criticidad));

        const planta_nueva = {
          ...planta,
          alarmas,
          alarmas_huawei,
          alarmas_sma
        }
        
        return planta_nueva;
      });

      this.regenerarMarcadores();
    },

    crearUrlCoordenadas(marker){
      let lat = marker.position.lat;
      let lng = marker.position.lng;
      let url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

      const notificar = this.notifyVue;

      navigator.clipboard.writeText(url).then(function() {
        notificar('Link a Google Maps copiado en portapapeles', 'success');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },

    boxCriticidadClickeado(criticidad){
      this.filtros_criticidad_activos[criticidad] = !this.filtros_criticidad_activos[criticidad];

      this.filtros_criticidad_seleccionados = Object.keys(this.filtros_criticidad_activos)
      .filter(item => this.filtros_criticidad_activos[item]);

      this.regenerarAlarmasPorPlanta();
    }
  }
}
</script>

<style scoped lang="scss">
.google-map {
  width: 100%;
  height: 620px;
}

.titulo-alarma-alta {
  color: #ff0000;
}

.titulo-alarma-media {
  color: #6f3199;
}

.titulo-alarma-baja {
  color: #25bea3;
}

.criticidad-box {
  border-style: solid;
  border-width: 2px;

  padding: 2px 10px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  &-disabled {
    background: darken(white, 10);
  }
}

.criticidad-box {
  &-baja {
    border-color: #25bea3;
  }

  &-media {
    border-color: #6f3199;
  }

  &-alta {
    border-color: #ff0000;
  }
}
</style>