<template>
  <div>
    <h2>Dashboard General de Portafolios: {{grupo_portafolio_codigo}}</h2>

    <!-- Seleccionar fecha -->
    <div class="row text-center mb-4">
      <div class="col-12">
        <h3 v-if="mes_dashboard" style="text-transform:capitalize">
          {{mes_dashboard.format('MMMM YYYY')}}
        </h3>
      </div>
      <div class="col-12">
        <p>Escoger mes</p>
        <date-picker 
          class="datepicker"
          :clearable=false
          v-model="fecha_dashboard"
          type="month"
          valueType="format"
          @input="cambiarMesDashboard"
        >
        </date-picker>
      </div>
    </div>

    <!-- Gráficos -->
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="row">
              <div class="col-sm-4 text-left">
                <h2 class="card-title">{{graficoActivo.toUpperCase()}}</h2>
              </div>
              <div class="col-sm-4 text-center">
                <input type="checkbox" v-model="sorted" @change="generarGraficos()" name="sorted">
                <label class="ml-2" for="sorted">Mayor a menor simulación?</label>
              </div>
              <div class="col-sm-4">
                <div class="btn-group btn-group-toggle float-right"
                     data-toggle="buttons">
                  <label v-for="option in tipoGraficos"
                    :key="option"
                    class="btn btn-sm btn-primary btn-simple"
                    :class="{active: graficoActivo === option}"
                    :id="option"
                  >
                    <input type="radio"
                      @click="cambiarTipoGrafico(option)"
                      name="options" autocomplete="off"
                      :checked="graficoActivo === option"
                    >
                    {{option}}
                  </label>
                </div>
              </div>
            </div>
          </template>
          <div>
            <i v-if="loading_pvsol" class="fas fa-spinner fa-spin"></i>
          </div>
          <div v-show="graficoActivo=='disp.' && !loading_pvsol">
            <div v-if="graficoDispData" class="chart-area">
              <bar-chart 
                :style="grafico_style"
                chart-id="grafico-dashboard-disp-real"
                ref="grafico-dashboard-disp-real"
                :chart-data="graficoDispData"
                :extra-options="graficoDispOptions"
              >
              </bar-chart >
            </div>
          </div>
          <div v-show="graficoActivo=='pr' && !loading_pvsol">
            <div v-if="graficoPRData" class="chart-area">
              <bar-chart 
                :style="grafico_style"
                chart-id="grafico-dashboard-pr"
                ref="grafico-dashboard-pr"
                :chart-data="graficoPRData"
                :extra-options="graficoPROptions"
              >
              </bar-chart >
            </div>
          </div>
          <div v-show="graficoActivo=='gen.' && !loading_pvsol">
            <div v-if="graficoGenData" class="chart-area">
              <bar-chart 
                :style="grafico_style"
                chart-id="grafico-dashboard-pr"
                ref="grafico-dashboard-pr"
                :chart-data="graficoGenData"
                :extra-options="graficoGenOptions"
              >
              </bar-chart >
            </div>
          </div>
          <div v-show="graficoActivo=='rendimiento específico' && !loading_pvsol">
            <div v-if="graficoRendEspData" class="chart-area">
              <bar-chart 
                :style="grafico_style"
                chart-id="grafico-dashboard-pr"
                ref="grafico-dashboard-pr"
                :chart-data="graficoRendEspData"
                :extra-options="graficoRendOptions"
              >
              </bar-chart >
            </div>
          </div>
          <div v-show="graficoActivo=='irrad.' && !loading_pvsol">
            <div v-if="graficoIrrData" class="chart-area">
              <bar-chart 
                :style="grafico_style"
                chart-id="grafico-dashboard-pr"
                ref="grafico-dashboard-pr"
                :chart-data="graficoIrrData"
                :extra-options="graficoIrrOptions"
              >
              </bar-chart >
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- Tabla -->
    <div class="row">
      <div class="col-12">
        <card>
          <div v-if="loading_portafolios">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          <div class="table-responsive" v-else>
            <table class="table tablesorter table-hover text-center table-dashboard">
              <thead>
                <tr>
                  <th>KPI</th>
                  <th></th>
                  <th v-for="portafolio in datos_portafolios" :key="'th_head_'+portafolio.codigo">
                    
                    <router-link
                      :to="{ name: 'dashboard', query: { portafolio: portafolio.codigo }}"
                    >
                      {{portafolio.nombre}}
                    </router-link>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>N° de Plantas</td>
                  <td></td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_n_'+portafolio.codigo">{{portafolio.n | numeral('0')}}</td>
                </tr>
                <tr>
                  <td>N° de Plantas Consideradas</td>
                  <td></td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_n_real_'+portafolio.codigo">{{portafolio.n_real | numeral('0')}}</td>
                </tr>
                <tr>
                  <td>kW Peak</td>
                  <td></td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_ppeak_'+portafolio.codigo">{{portafolio.p_peak_sum | numeral('0,0.0')}}</td>
                </tr>
                <tr class="tr-section">
                  <td>Generación</td>
                  <td>[kWh]</td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_gen_'+portafolio.codigo">{{portafolio.gen_acum | numeral('0,0.0')}}</td>
                </tr>
                <tr>
                  <td>Generación PVSol</td>
                  <td>[kWh]</td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_gen_pvsol_'+portafolio.codigo">{{pvsols[portafolio.codigo].gen_acum | numeral('0,0.0')}}</td>
                </tr>
                <tr class="tr-section">
                  <td>Irradiacion</td>
                  <td>[kWh/m2]</td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_irr_'+portafolio.codigo">{{portafolio.irr_acum | numeral('0,0.0')}}</td>
                </tr>
                <tr>
                  <td>Irradiación PVSol</td>
                  <td>[kWh/m2]</td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_irr_pvsol_'+portafolio.codigo">{{pvsols[portafolio.codigo].irr_acum | numeral('0,0.0')}}</td>
                </tr>
                <tr class="tr-section">
                  <td>Rendiemiento Específico</td>
                  <td>[kWh/kWp]</td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_rend_'+portafolio.codigo">{{portafolio.rend_esp | numeral('0,0.0')}}</td>
                </tr>
                <tr>
                  <td>Rendimiento Especifico PVSol</td>
                  <td>[kWh/kWp]</td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_rend_pvsol_'+portafolio.codigo">{{pvsols[portafolio.codigo].rend_esp | numeral('0,0.0')}}</td>
                </tr>
                <tr class="tr-section">
                  <td>PR Real</td>
                  <td>[%]</td>
                  <td 
                    v-for="portafolio in datos_portafolios"
                    :style="estiloPR(portafolio.pr_real_acum, pvsols[portafolio.codigo].pr_acum)"
                    :key="'td_pr_'+portafolio.codigo"
                  >
                    {{portafolio.pr_real_acum | numeral('0,0.0')}}
                  </td>
                </tr>
                <tr>
                  <td>PR O&M</td>
                  <td>[%]</td>
                  <td 
                    v-for="portafolio in datos_portafolios"
                    :style="estiloPR(portafolio.pr_om_acum, pvsols[portafolio.codigo].pr_acum)"
                    :key="'td_pr_'+portafolio.codigo"
                  >
                    {{portafolio.pr_om_acum | numeral('0,0.0')}}
                  </td>
                </tr>
                <tr>
                  <td>PR PVSol</td>
                  <td>[%]</td>
                  <td v-for="portafolio in datos_portafolios" :key="'td_pr_pvsol_'+portafolio.codigo">{{pvsols[portafolio.codigo].pr_acum | numeral('0,0.0')}}</td>
                </tr>
                <tr class="tr-section">
                  <td>Disponibilidad Real</td>
                  <td>[%]</td>
                  <td
                    v-for="portafolio in datos_portafolios"
                    :key="'td_disp_real_'+portafolio.codigo"
                    :style="estiloDisp(portafolio.disp_real_acum)"
                  >
                    {{portafolio.disp_real_acum | numeral('0,0.0')}}
                  </td>
                </tr>
                <tr>
                  <td>Disponibilidad O&M</td>
                  <td>[%]</td>
                  <td
                    v-for="portafolio in datos_portafolios"
                    :key="'td_disp_om_'+portafolio.codigo"
                    :style="estiloDisp(portafolio.disp_om_acum)"
                  >
                    {{portafolio.disp_om_acum | numeral('0,0.0')}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { BaseTable } from "@/components";
import DatePicker from 'vue2-datepicker';

import BarChart from '@/components/Charts/BarChart';
import {
  dashboardPRChartOptions,
  dashboardDispChartOptions,
  dashboardGenPortafoliosChartOptions,
  dashboardIrrPondPortafoliosChartOptions,
  dashboardRendChartOptions
} from '@/components/Charts/config';

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, {locale: 'es'});

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
require('dayjs/locale/es');
dayjs.locale('es');

export default {
  name: 'dashboard-portafolios',
  components: {
    BaseTable,
    DatePicker,
    BarChart
  },
  data(){
    return {
      fecha_actual: null,
      loading_portafolios: true,
      loading_pvsol: true,
      mes_dashboard: null,
      fecha_dashboard: null,
      dias_mes: 0,
      tipoGraficos: [
        'disp.',
        'pr',
        'gen.',
        'irrad.',
        'rendimiento específico',
      ],
      graficoActivo: 'gen.',
      sorted: false,
      graficoDispData: null,
      graficoPRData: null,
      graficoGenData: null,
      graficoRendEspData: null,
      graficoIrrData: null,
      graficoDispOptions: dashboardDispChartOptions,
      graficoPROptions: dashboardPRChartOptions,
      graficoGenOptions: dashboardGenPortafoliosChartOptions,
      graficoIrrOptions: dashboardIrrPondPortafoliosChartOptions,
      graficoRendOptions: dashboardRendChartOptions,
      datos_portafolios: [],
      grafico_style: "height:400px; width:100%;",
      grupo_portafolio_codigo: '',
      pvsols: {},
    }
  },
  methods: {
    estiloPR(n, esperado){
      if(!esperado){
        return {}
      }

      let porc = n/esperado;

      if(porc>1){
        return {'color': 'blue !important'}
      }
      else if(porc>=0.97 && porc <= 1){
        return {'color': '#d4b726 !important'}
      }
      else {
        return {'color': 'red !important'}
      }
    },

    estiloDisp(n){

      if(n>=97){
        return {'color': 'blue !important'}
      }
      else {
        return {'color': 'red !important'}
      }
    },

    cambiarTipoGrafico(tipo){
      this.graficoActivo = tipo;
    },
    
    generarGraficos(){
      this.generarGraficoDisp();
      this.generarGraficoPR();
      this.generarGraficoGen();
      this.generarGraficoRendEsp();
      this.generarGraficoIrr();
    },

    generarGraficoDisp(){
      let data = [];
      let labels = [];
      let dispReal = [];
      let dispOm = [];
      let dispEsperada = [];
      let coloresReal = [];
      let coloresOM = [];

      this.datos_portafolios.forEach(item => {
        data = data.concat({
          nombre: item.nombre,
          dataReal: item.disp_real_acum,
          dataOm: item.disp_om_acum,
          dataEsperada: 97
        })
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }

      data.forEach(item => {
        labels.push(item.nombre);
        dispReal.push(item.dataReal.toFixed(1));
        dispOm.push(item.dataOm.toFixed(1));
        dispEsperada.push(item.dataEsperada.toFixed(1));

        let colorReal = item.dataReal < 97 ? 'red' : '#4472C4';
        let colorOM = item.dataOm < 97 ? 'red' : '#ED7D31';
        
        coloresReal.push(colorReal);
        coloresOM.push(colorOM);
      });

      this.graficoDispData = {
        labels: labels,
        datasets: [
          {
            label: "Disp. Esperada (97%)",
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: 'red',
            borderWidth: 2,
            pointRadius: 0,
            data: dispEsperada,
            backgroundColor: "red",
          },
          {
            label: "Disp. Real Ponderada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: coloresReal,
            borderWidth: 2,
            pointRadius: 0,
            data: dispReal,
            backgroundColor: coloresReal,
          },
          {
            label: "Disp. O&M Ponderada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: coloresOM,
            borderWidth: 2,
            pointRadius: 0,
            data: dispOm,
            backgroundColor: coloresOM,
          },
        ]
      }
    },

    generarGraficoPR(){
      let data = [];
      let labels = [];
      let prOm = [];
      let prEsperado = [];
      let colores = [];

      this.datos_portafolios.forEach(item => {
        data = data.concat({
          nombre: item.nombre,
          dataReal: item.pr_om_acum,
          dataEsperada: this.pvsols[item.codigo]['pr_acum']
        })
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }

      data.forEach(item => {
        labels.push(item.nombre);
        prOm.push(item.dataReal.toFixed(1));
        prEsperado.push(item.dataEsperada.toFixed(1));

        let color = item.dataReal < item.dataEsperada ? 'red' : '#4472C4';
        colores.push(color);
      });

      this.graficoPRData = {
        labels: labels,
        datasets: [
          {
            label: "PR O&M Ponderado",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: colores,
            borderWidth: 2,
            pointRadius: 0,
            data: prOm,
            backgroundColor: colores,
          },
          {
            label: "PR Simulado Ponderado",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: prEsperado,
            backgroundColor: "#ED7D31",
          },
        ]
      }
    },

    generarGraficoGen(){
      let data = [];
      let labels = [];
      let genAcum = [];
      let genEsp = [];
      let colores = [];
      const diferencias = [];

      this.datos_portafolios.forEach(item => {
        data = data.concat({
          nombre: item.nombre,
          dataReal: item.gen_acum,
          dataEsperada: this.pvsols[item.codigo]['gen_acum']
        })
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }

      data.forEach(item => {
        labels.push(item.nombre);
        genAcum.push(item.dataReal.toFixed(1));
        genEsp.push(item.dataEsperada.toFixed(1));

        if(item.dataEsperada === 0){
          if(item.dataReal === 0){
            diferencias.push(0)
          }
          else {
            diferencias.push(100)
          }
        }
        else{
          const diff = ((item.dataReal/item.dataEsperada)-1)*100;
          diferencias.push(diff.toFixed(1))
        }

        let color = item.dataReal < item.dataEsperada ? 'red' : '#4472C4';
        colores.push(color);
      });

      this.graficoGenData = {
        labels: labels,
        datasets: [
          {
            label: "Generación Acumulada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: colores,
            borderWidth: 2,
            pointRadius: 0,
            data: genAcum,
            backgroundColor: colores,
          },
          {
            label: "Generación Simulada Acumulada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: genEsp,
            backgroundColor: "#ED7D31",
          },
          {
            label: "Diferencia",
            yAxisID: 'B',
            type: 'scatter',
            fill: true,
            pointRadius: 0,
            pointHoverRadius: 0,
            data: diferencias,
            show: false,
            display: false,
            hidden: false,

          },
        ]
      }
    },

    generarGraficoRendEsp(){
      let data = [];
      let labels = [];
      let genAcum = [];
      let genEsp = [];
      let colores = [];

      this.datos_portafolios.forEach(item => {
        data = data.concat({
          nombre: item.nombre,
          dataReal: item.rend_esp,
          dataEsperada: this.pvsols[item.codigo]['rend_esp']
        })
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }
      
      data.forEach(item => {
        labels.push(item.nombre);
        genAcum.push(item.dataReal.toFixed(1));
        genEsp.push(item.dataEsperada.toFixed(1));

        let color = item.dataReal < item.dataEsperada ? 'red' : '#4472C4';
        colores.push(color);
      });

      this.graficoRendEspData = {
        labels: labels,
        datasets: [
          {
            label: "Rendimiento Específico",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: colores,
            borderWidth: 2,
            pointRadius: 0,
            data: genAcum,
            backgroundColor: colores,
          },
          {
            label: "Rendimiento Específico Simulado",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: genEsp,
            backgroundColor: "#ED7D31",
          },
        ]
      }
    },

    generarGraficoIrr(){
      let data = [];
      let labels = [];
      let irrAcum = [];
      let irrEsp = [];
      const diferencias = [];

      this.datos_portafolios.forEach(item => {
        data = data.concat({
          nombre: item.nombre,
          dataReal: item.irr_acum,
          dataEsperada: this.pvsols[item.codigo]['irr_acum']
        })
      })

      if(this.sorted){
        data = data.sort((a,b) => (a.dataEsperada < b.dataEsperada) ? 1 : ((b.dataEsperada < a.dataEsperada) ? -1 : 0))
      }

      data.forEach(item => {
        labels.push(item.nombre);
        irrAcum.push(item.dataReal.toFixed(1));
        irrEsp.push(item.dataEsperada.toFixed(1));

        if(item.dataEsperada === 0){
          if(item.dataReal === 0){
            diferencias.push(0)
          }
          else {
            diferencias.push(100)
          }
        }
        else{
          const diff = ((item.dataReal/item.dataEsperada)-1)*100;
          diferencias.push(diff.toFixed(1))
        }
      });

      this.graficoIrrData = {
        labels: labels,
        datasets: [
          {
            label: "Irradiación Ponderada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: irrAcum,
            backgroundColor: "#4472C4",
          },
          {
            label: "Irradiación Simulada Ponderada",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: irrEsp,
            backgroundColor: "#ED7D31",
          },
          {
            label: "Diferencia",
            yAxisID: 'B',
            type: 'scatter',
            fill: true,
            pointRadius: 0,
            pointHoverRadius: 0,
            data: diferencias,
            show: false,
            display: false,
            hidden: false,

          },
        ]
      }
    },

    async cambiarMesDashboard(fecha){
      let factor = 1;
      let inicio_acc = dayjs(fecha+'-01');
      let mes_dashboard = inicio_acc.startOf('month');
      let inicio_mes = mes_dashboard.startOf('month');
      let fin_mes = mes_dashboard.endOf('month');

      this.dias_mes = fin_mes.diff(inicio_mes, 'day')+1;
      this.mes_dashboard = mes_dashboard;

      this.loading_portafolios = true;
      this.loading_pvsol = true;

      if(this.fecha_actual.isSame(this.mes_dashboard, 'month')){
        factor = dia_anterior.date()/this.dias_mes;
      }

      try {
        let res_pvsol = await this.$http({
          method: 'GET',
          url: '/api/pvsol/portafolios',
          params: {
            mes: inicio_acc.month()+1,
            ano: inicio_acc.year(),
            factor,
            grupo: this.grupo_portafolio_codigo
          }
        });

        this.pvsols = res_pvsol.data;
        this.loading_pvsol = false;

        let res = await this.$http({
          method: 'GET',
          url: '/api/dashboard/portafolios',
          params: {
            grupo: this.grupo_portafolio_codigo,
            inicio_acc: inicio_acc.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            fin_mes: fin_mes.format('YYYY-MM-DD')
          }
        });

        this.datos_portafolios = res.data;
        this.loading_portafolios = false;

        this.generarGraficos();
      }
      catch (error) {
        this.notifyVue('Error al pedir datos', 'warning');
        console.log(error);
      }
    },
  },
  async mounted(){
    this.grupo_portafolio_codigo = this.$route.query.grupo == null ? 'GDA' : this.$route.query.grupo;

    this.fecha_actual = dayjs();
    let factor = 1;
    let dia_anterior = this.fecha_actual.endOf('day').subtract(1, 'day').startOf('day');
    let inicio_acc = dia_anterior.startOf('month');
    this.fecha_dashboard = inicio_acc.format('YYYY-MM')
    // let fin_acc = dia_anterior;

    let mes_dashboard = dia_anterior.startOf('month');
    let inicio_mes = mes_dashboard.startOf('month');
    let fin_mes = mes_dashboard.endOf('month');

    this.dias_mes = fin_mes.diff(inicio_mes, 'day')+1;
    this.mes_dashboard = mes_dashboard;

    if(this.fecha_actual.isSame(this.mes_dashboard, 'month')){
      factor = dia_anterior.date()/this.dias_mes;
    }

    try {
      let res_pvsol = await this.$http({
        method: 'GET',
        url: '/api/pvsol/portafolios',
        params: {
          mes: inicio_acc.month()+1,
          ano: inicio_acc.year(),
          factor,
          grupo: this.grupo_portafolio_codigo
        }
      });

      
      this.pvsols = res_pvsol.data;
      this.loading_pvsol = false;

      let res = await this.$http({
        method: 'GET',
        url: '/api/dashboard/portafolios',
        params: {
          grupo: this.grupo_portafolio_codigo,
          inicio_acc: inicio_acc.utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          fin_mes: fin_mes.format('YYYY-MM-DD')
        }
      });
      
      this.datos_portafolios = res.data;
      this.loading_portafolios = false;

      this.generarGraficos();
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss">
.chart-area {
  margin: 30px 0;
  border: solid 1px #60864c;
  padding: 5px
}

.tr-section{
  border-top: 2px solid black;
}
</style>