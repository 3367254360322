<template>
  <div>
    <h1>Plantas</h1>

    <div class="row">
      <div class="col-12">
        <card>
          <router-link
            :to="{ name: 'plantas-create'}"
          >
            <button class="btn">
              Agregar Planta Fronius
            </button>
          </router-link>
          <router-link
            :to="{ name: 'plantas-create-huawei'}"
          >
            <button class="btn btn-warning">
              Agregar Planta Huawei
            </button>
          </router-link>
        </card>
      </div>

      <div class="col-12">
        <modal 
          :show="mostrarModalEliminar"
          body-classes="p-0"
          modal-classes="modal-dialog-centered modal-lg"
        >
          <template slot="header">
            <h1>Adventencia!</h1>
          </template>
          <card 
            type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0"
          >
            <template>
              <h2>Se eliminarán todos los datos de la planta seleccionada</h2>
              <h3 v-if="plantaEliminar">{{plantaEliminar.nombre}}</h3>
            </template>
          </card>
          <template slot="footer">
            <base-button type="primary" @click="eliminarPlanta(plantaEliminar)">
              Aceptar
            </base-button>
            <base-button type="secondary" class="ml-auto" @click="cerrarModalEliminar">
              Cancelar
            </base-button>
          </template>
        </modal>
        <card>
          <div class="table-responsive">
            <table class="table tablesorter table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in plantas" :key="index">
                  <td>
                    <router-link
                      :to="{ name: 'planta', params: { id: item.id }}"
                    >
                      {{item.nombre}}
                    </router-link>
                  </td>
                  <td>
                    <button
                      v-if="false"
                      class="btn btn-danger"
                      @click="abrirModalEliminar(item.id)"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseTable } from "@/components";
import Modal from '@/components/Modal';
// import axios from 'axios';

// var backend_db_instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_DB_URL,
//   headers: {
//     Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjMzMzU5NzIyLCJqdGkiOiI5NWNlOTg4ZDZlOTM0OTRiOGI1OGE2NTEzOTNlNjY2ZCIsInVzZXJfaWQiOjJ9.MU9kngGu_Ky6t2WcUhe2phTFZeEbl1h1yfHvJ9gVerI',
//   }
// });

export default {
  name: 'plantas-page',
  components: {
    BaseTable,
    Modal
  },
  computed: {
  },
  data() {
    return {
      plantas: [],
      plantaEliminar: null,
      mostrarModalEliminar: false,
    };
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    abrirModalEliminar(id){
      this.plantaEliminar = this.plantas.find(planta => planta.id==id);
      this.mostrarModalEliminar = true;
    },

    cerrarModalEliminar(){
      this.plantaEliminar = null;
      this.mostrarModalEliminar = false;
    },

    async eliminarPlanta(planta){
      try {
        let res = await this.$http({
          method: 'DELETE',
          url: '/api/plantas/'+planta.id
        });

        // this.plantas = res.data;
        console.log('Eliminada');
        this.plantas = this.plantas.filter(item => item.id != planta.id);
        this.plantaEliminar = null;
        this.mostrarModalEliminar = false;

        this.notifyVue('Se ha eliminado la planta', 'success');
      } catch (error) {
        this.notifyVue('No se ha podido eliminar la planta', 'danger');
        console.log(error);
      }
    }
  },
  async mounted(){

    try {
      let res = await this.$http({
        method: 'GET',
        url: '/api/plantas',
        params: {
          portafolio: this.$route.query.portafolio,
        }
      });

      this.plantas = res.data;

    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style>
</style>
