import InvitadoSidebar from "./InvitadoSideBar.vue";
// import SidebarLink from "./SidebarLink";

const InvitadoSidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  displaySidebar(value) {
    this.showSidebar = value;
  }
};

const InvitadoSidebarPlugin = {
  install(Vue) {
    let app = new Vue({
      data: {
        InvitadosidebarStore: InvitadoSidebarStore
      }
    });

    Vue.prototype.$invitadosidebar = app.InvitadosidebarStore;
    Vue.component("invitado-side-bar", InvitadoSidebar);
    // Vue.component("invitado-sidebar-link", SidebarLink);
  }
};

export default InvitadoSidebarPlugin;
