<template>
  <div class="row section-header">
    <div class="left">
      N° {{codigo}}
    </div>
    <div class="right">
      <img
        v-if="portafolio === 'ENELX'"
        src="/img/enelx.png"
        style="height: 45px; position: absolute; top: 35px; right: 65px; border-radius: 0"
        alt="Logo Enel X"
      >
      <img
        v-else
        src="/img/delta.png"
        style="height: 45px; position: absolute; top: 35px; right: 65px; border-radius: 0"
        alt="Logo Delta Activos"
      >
    </div>
    <br>
    <hr>
  </div>
</template>

<script>
export default {
  name: 'reporte-header',
  props: {
    codigo: {
      type: String,
      default: '--'
    },
    portafolio: {
      type: String,
      default: '4TC'
    }
  },
  data(){
    return {
      portafolios_delta: ['DAGDA', 'ENLASA', 'SINVEST1', 'SINVEST2']
    }
  }
}
</script>

<style lang="scss" scoped>
  .section-header {
    > .left {
      color: #000;
    }
  }
</style>