<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="header-body text-center">
            <h1>Asignar Plantas a: {{usuario.nombre}} {{usuario.apellido}}</h1>
          </div>
        </div>
      </div>

      <div class="row">
        <div v-for="grupo in grupos" :key="grupo.codigo" class="col-12">
          <card>
            <h2>{{grupo.nombre}}</h2>
            <div class="table-responsive">
              <table class="table tablesorter table-hover">
                <thead>
                  <tr>
                    <th>Portafolio/Planta</th>
                    <th>Asignar</th>
                  </tr>
                </thead>
                <tbody v-for="portafolio in grupo.portafolios" :key="portafolio.codigo">
                  <tr>
                    <td>
                      <b>{{portafolio.nombre}}</b>
                    </td>
                    <td>
                    </td>
                  </tr>
                  <tr v-for="planta in portafolio.plantas" :key="planta.id">
                    <td class="ml-4">
                      {{planta.nombre}}
                    </td>
                    <td>
                      <input type="checkbox" :id="planta.id" :value="planta.id" v-model="ids_seleccionadas"></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </card>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class=" text-center">
            <button class="btn btn-danger" @click="guardarAsignaciones">
              Guardar Cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'asignacion',
  components: {

  },
  data () {
    return {
      grupos: {},
      usuario: {},
      ids_seleccionadas: [],
    }
  },
  computed: {
    listado_grupos(){
      return Object.keys(this.grupos)
    }
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },
    
    async guardarAsignaciones() {
      
      try {
        await this.$http({
          method: 'POST',
          url: '/api/usuarios/'+this.usuario.id+'/asignar',
          data: {
            plantas: this.ids_seleccionadas
          }
        });

        this.notifyVue('Se han guardado los cambios', 'success');
      } catch (error) {
        this.notifyVue('Hubo un error al guardar los cambios', 'danger');
        console.error(error);
      }
    }
  },
  async mounted(){
    let id = this.$route.params.id;
    try {
      let res_usuario = await this.$http({
        method: 'GET',
        url: '/api/usuarios/'+id+'/asignadas',
      });

      this.usuario = res_usuario.data;

      this.ids_seleccionadas = res_usuario.data.planta_invitados.map(x => x.planta_id);

      let res = await this.$http({
        method: 'GET',
        url: '/api/portafolios/listado',
      });

      this.grupos = res.data;

    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style>

</style>