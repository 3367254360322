import Vue from 'vue';

const state = {
  grupos: [],
}

const mutations = {
  SET_GRUPOS(state, grupos){
    state.grupos = grupos;
  }
//   SET_CURRENT_PLANT(state, planta){
//     state.planta = planta;
//   },

//   SET_CURRENT_REPORT(state, reporte){
//     state.reporte = reporte;
//   },

//   SET_FECHAS_LIMITE(state, payload){
//     state.fecha_inicio = payload.fecha_inicio;
//     state.fecha_fin = payload.fecha_fin;
//   },

//   SET_GENERATION_DATA(state, payload){
//     state.generationData = payload
//   },

//   SET_IRRADIATION_DATA(state, payload){
//     state.irradiationData = payload
//   },

//   SET_IRRADIATION_INC_DATA(state, payload){
//     state.irradiationInc = payload
//   },

//   SET_IRRADIATION_MENSUAL_INC_DATA(state, payload){
//     state.irradiationMensualInc = payload
//   },

//   SET_SMART_DATA(state, payload){
//     state.smartData = payload;
//   },

//   SET_TEMPERATURA_DATA(state, payload){
//     state.temperaturaData = payload;
//   },

//   SET_RADIACION_PMGD_DATA(state, payload){
//     state.radiacionPmgdData = payload;
//   },
  
//   SET_ESTADO_DATA(state, payload){
//     state.estadoInversoresData = payload;
//   },

//   SET_DEFAULT_STATE(state){
//     state.planta = null;
//     state.reporte = null;
//     state.fecha_inicio = null;
//     state.fecha_fin = null;
//     state.generationData = [];
//     state.irradiationData = [];
//     state.irradiationDataInc = [];
//     state.irradiationMensualInc = [];
//     state.smartData = [];
//     state.radiacionPmgdData = [];
//     state.temperaturaData = []
//   }
};

const actions = {
  async fetchPortafolios({commit}, params){
    console.log('Pidiendo listado de portafolios');
    try {
      let res = await Vue.prototype.$http({
        method: 'GET',
        url: '/api/portafolios/listado',
      });

      commit('SET_GRUPOS', res.data);
    } catch(error) {
      console.error('error')
    }
  }
//   async getPlantById({commit}, id){
//     try {
//       let res = await Vue.prototype.$http({
//         method: 'GET',
//         url: 'api/plantas/'+id,
//       });

//       console.log('Datos de planta obtenidos');
//       commit('SET_CURRENT_PLANT', res.data);
//     } catch(error) {
//       console.log(error);
//     }
//   },

//   async getReportById({commit}, payload){
//     try {
//       let res = await Vue.prototype.$http({
//         method: 'GET',
//         url: 'api/plantas/'+payload.id_planta+'/reportes/'+ payload.id_reporte+'/dashboard',
//       });

//       console.log('Datos de reporte obtenidos');
//       commit('SET_CURRENT_REPORT', res.data);
//     } catch(error) {
//       console.log(error);
//     }
//     // let reporte = state.planta.reportes.filter((item) => {
//     //   if(item.id == id){
//     //     return item;
//     //   }
//     // })[0]
//     // commit('SET_CURRENT_REPORT', reporte);
//   },

//   getFechasLimite({commit}){
//     let fecha_inicio = dayjs(state.reporte.fecha_inicio, 'YYYY-MM-DD');
//     let fecha_fin = dayjs(state.reporte.fecha_fin, 'YYYY-MM-DD').endOf('day');
//     // let fecha_inicio = fecha.startOf('month');
//     // let fecha_fin = fecha.endOf('month');
//     let fecha_actual = dayjs().endOf('day');

//     if(fecha_actual.isBefore(fecha_fin)){
//       fecha_fin = fecha_actual.subtract(1, 'day');
//     }

//     let payload = {
//       fecha_inicio,
//       fecha_fin
//     };

//     commit('SET_FECHAS_LIMITE', payload);
//     // return new Promise((resolve, reject) => {
//     //   try {
//     //     let fecha = dayjs(state.reporte.fecha, 'YYYY-MM-DD');
//     //     let fecha_inicio = fecha.startOf('month');
//     //     let fecha_fin = fecha.endOf('month');
//     //     let fecha_actual = dayjs().endOf('day');

//     //     if(fecha_actual.isBefore(fecha_fin)){
//     //       fecha_fin = fecha_actual;
//     //     }

//     //     let payload = {
//     //       fecha_inicio,
//     //       fecha_fin
//     //     };

//     //     commit('SET_FECHAS_LIMITE', payload);
//     //     resolve('success')
//     //   } catch (error) {
//     //     console.log(error);
//     //     reject('error')
//     //   }
      
//     // })
//   },

//   async getGenerationData({commit}, payload){
//     try {
//       let res = await Vue.prototype.$http({
//         method: 'POST',
//         url: 'api/generacion',
//         data: payload,
//       });

//       console.log('Datos de generacion obtenidos');
//       let genData = res.data.map((item, idx) => {
//         item['Fecha'] = dayjs(item['Fecha']);
//         item['idx'] = idx;
//         return item
//       });

//       // this.generationData = genData;
//       commit('SET_GENERATION_DATA', genData);

//     } catch(error) {
//       console.log(error);
//     }
//   },

//   async getIrradiationData({commit}, payload){
//     let poa = payload.poa;
//     try {
//       let res = await Vue.prototype.$http({
//         method: 'POST',
//         url: 'api/irradiacion',
//         data: payload
//       });

//       let irrData = res.data.map((item, idx) => {
//         item['Fecha'] = dayjs(item['Fecha']);
//         item['idx'] = idx;

//         for(let key in poa){
//           if(item['irradiacion']){
//             item[key] = item['irradiacion']*poa[key]*(5/60000);
//           }
//           else{
//             item[key] = item['irradiacion']
//           }
            
//         }

//         item['Global'] = item['irradiacion']
//         delete item.irradiacion;

//         return item
//       })

//       console.log('Datos de irradiacion obtenidos');
//       // this.irradiationData = irrData;
//       commit('SET_IRRADIATION_DATA', irrData);
//     } catch(error){
//       console.log(error);
//     }
//   },

//   async getSmartData({commit}, payload){
//     try {
//       let res = await Vue.prototype.$http({
//         method: 'POST',
//         url: 'api/smart',
//         data: payload,
//       });

//       console.log('Datos de autoconsumo obtenidos');
//       let smartData = res.data.map((item, idx) => {
//         item['Fecha'] = dayjs(item['Fecha']);
//         item['idx'] = idx;
//         return item
//       });

//       commit('SET_SMART_DATA', smartData);

//     } catch(error) {
//       console.log(error);
//     }
//   },

//   async getTemperaturaData({commit}, payload){
//     try {
//       let res = await Vue.prototype.$http({
//         method: 'POST',
//         url: 'api/temperatura',
//         data: payload,
//       });

//       console.log('Datos de temperatura obtenidos');
//       let data = res.data.map((item, idx) => {
//         item['Fecha'] = dayjs(item['Fecha']);
//         item['idx'] = idx;
//         return item
//       });

//       commit('SET_TEMPERATURA_DATA', data);

//     } catch(error) {
//       console.log(error);
//     }
//   },

//   async getIrradiationPmgdData({commit}, payload){
//     let poa = payload.poa;
//     let coef_temperatura = payload.coef_temperatura;
//     let temperatura = state.temperaturaData;


//     try {
//       let res = await Vue.prototype.$http({
//         method: 'POST',
//         url: 'api/irradiacion-pmgd',
//         data: payload
//       });

//       let irrData = res.data.map((item, idx) => {
//         item['Fecha'] = dayjs(item['Fecha']);
//         item['idx'] = idx;

//         for(let codigo_inversor in poa){
//           if(item['irradiacion']){
//             // item[codigo_inversor] = item['irradiacion']*poa[codigo_inversor]*(5/60000);
//             let fact_temp = 1 + (((temperatura[idx].temperatura-25)*coef_temperatura[codigo_inversor])/100)
//             item[codigo_inversor] = item['irradiacion']*poa[codigo_inversor]*(5/60000)*fact_temp;
//           }
//           else{
//             // item[codigo_inversor] = item['irradiacion']
//             item[codigo_inversor] = null;
//           }
//         }

//         item['Global'] = item['irradiacion']
//         delete item.irradiacion;

//         return item
//       })

//       console.log('Datos de irradiacion obtenidos');
//       // this.irradiationData = irrData;
//       commit('SET_RADIACION_PMGD_DATA', irrData);
//     } catch(error){
//       console.log(error);
//     }
//   },

//   async getEstadoInversoresData({commit}, payload){
//     try {
//       let res = await Vue.prototype.$http({
//         method: 'POST',
//         url: 'api/estado-inversores',
//         data: payload,
//       });

//       console.log('Datos de estado obtenidos');
//       let data = res.data.map((item, idx) => {
//         item['Fecha'] = dayjs(item['Fecha']);
//         item['idx'] = idx;
//         return item
//       });

//       commit('SET_ESTADO_DATA', data);

//     } catch(error) {
//       console.log(error);
//     }
//   },

//   resetDefaultState({commit}){
//     commit('SET_DEFAULT_STATE');
//   }
};

const getters = {
  grupos: state => state.grupos,
};

const grupos = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};


export default grupos;