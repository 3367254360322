<template>
  <div>
    <h1>Crear Planta Ingeteam</h1>

    <label class="typo__label">Seleccionar Planta (Ingeteam)</label>
    <multiselect v-model="selectedPlanta" :options="optionsPlantas" @select="plantaSeleccionada"
      placeholder="Seleccionar Planta" label="name" track-by="name">
    </multiselect>

    <div class="row">
      <div class="col-12 mt-4">
        <div class="row">
          <div class="col-md-4">
            <base-input
              label="Nombre"
              placeholder="Nombre"
              v-model="dataPlanta.nombre"
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Nombre Visible"
              placeholder="Nombre Visible"
              v-model="dataPlanta.nombre_visible"
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Código"
              placeholder="Código"
              v-model="dataPlanta.codigo"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <base-input
              label="PvSystemId"
              placeholder="PvSystemId"
              v-model="dataPlanta.PvSystemId"
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Portafolio">
              <select class="form-control" id="portafolio" v-model="dataPlanta.portafolio">
                <option v-for="portafolio in portafolios" :key="portafolio.codigo" :value="portafolio.codigo">{{portafolio.nombre}}</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Posición en Portafolio"
              placeholder="Posición en Portafolio"
              v-model="dataPlanta.pos_portafolio"
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <base-input
              label="N° de Paneles"
              placeholder="N° de Paneles"
              v-model="dataPlanta.n_paneles"
              type="number"
              min=1
              max=1000
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Potencia Nominal [kWn]"
              placeholder="Potencia Nominal"
              v-model="dataPlanta.p_nom"
              type="number"
              min=1
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="P. Peak [kWp]"
              placeholder="P. Peak"
              v-model="dataPlanta.p_peak"
              type="number"
              min=1
            >
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <base-input
              label="Coef. Garantizado PR"
              placeholder="0-100"
              v-model="dataPlanta.coef_pr_garantizado"
              type="number"
              min=0
              max=100
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Disponibilidad Garantizada"
              placeholder="0-100"
              v-model="dataPlanta.disp_garantizado"
              type="number"
              min=0
              max=100
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <label class="control-label">
              Fecha de Inicio de Operación
            </label>
            <br>
            <date-picker
              type="date"
              class="datepicker"
              v-model="dataPlanta.fecha_inicio"
              valueType="format"
            >
            </date-picker>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-4">
            <base-input label="Tipo de Contrato">
              <select class="form-control" id="tipo_contrato" v-model="dataPlanta.tipo_contrato">
                <option value="FIJO">Fijo</option>
                <option value="VARIABLE">Variable</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Porcentaje de Contrato"
              placeholder="0-100"
              v-model="dataPlanta.porc_contrato"
              type="number"
              min=0
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Tipo de Descuento">
              <select class="form-control" id="tipo_descuentos" v-model="dataPlanta.tipo_descuentos">
                <option v-for="tipo_descuentos in tipos_descuentos" :key="tipo_descuentos.codigo" :value="tipo_descuentos.codigo">
                  {{tipo_descuentos.nombre}}
                </option>
              </select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <base-input label="Usa Dcto. Diferido">
              <select class="form-control" id="usa_dcto_diferida" v-model="dataPlanta.usa_dcto_diferida">
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Porcentaje de Contrato Autoconsumo"
              placeholder="0-100"
              v-model="dataPlanta.porc_contrato_autoconsumo"
              type="number"
              min=0
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Tipo de Descuento Autoconsumo">
              <select class="form-control" id="tipo_descuentos_autoconsumo" v-model="dataPlanta.tipo_descuentos_autoconsumo">
                <option v-for="tipo_descuentos in tipos_descuentos" :key="tipo_descuentos.codigo+'_ac'" :value="tipo_descuentos.codigo">
                  {{tipo_descuentos.nombre}}
                </option>
              </select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <base-input label="Tipo de Instalación">
              <select class="form-control" id="tipo_instalacion" v-model="dataPlanta.tipo_instalacion">
                <option value="PISO">Piso</option>
                <option value="TECHO">Techo</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Es planta PMGD?">
              <select class="form-control" id="es_pmgd" v-model="dataPlanta.es_pmgd">
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Coef. Radiacion Trasera"
              placeholder="0-100"
              v-model="dataPlanta.pmgd_trasera"
              type="number"
              min=0
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Periodo de Datos">
              <select class="form-control" id="periodo" v-model="dataPlanta.periodo">
                <option v-for="periodo in periodos" :key="periodo.key" :value="periodo.key">{{periodo.label}}</option>
              </select>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <base-input label="Tiene Sensor de Irradiación?">
              <select class="form-control" id="tiene_sensor_irr" v-model="dataPlanta.tiene_sensor_irr">
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Tiene Smart Meter?">
              <select class="form-control" id="tiene_smart_meter" v-model="dataPlanta.tiene_smart_meter">
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Tipo de Reporte">
              <select class="form-control" id="tiporeporte" v-model="dataPlanta.tipo_reporte">
                <option value="SINREPORTEFINANCIERO">Sin Reporte Financiero</option>
                <!-- <option value="ESCOFIJO">ESCO Fijo</option>
                <option value="ESCODIFERIDO">ESCO Variable Diferido</option>
                <option value="ESCOTOTAL">ESCO Variable Total</option> -->
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Degradación Año 1"
              placeholder="0-100%"
              v-model="dataPlanta.degradacion_primer_ano"
              type="number"
              min=0
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input
              label="Degradación Lineal"
              placeholder="0-100%"
              v-model="dataPlanta.degradacion_lineal"
              type="number"
              min=0
            >
            </base-input>
          </div>
          <div class="col-md-4">
            <label class="typo__label">Comuna</label>
            <multiselect v-model="selectedComuna" :options="comunas"
              placeholder="Comuna" label="nombre" track-by="nombre">
            </multiselect>
          </div>
          <div class="col-md-4">
            <base-input label="Distribuidora">
              <select class="form-control" id="distribuidora" v-model="dataPlanta.distribuidora">
                <option v-for="distribuidora in distribuidoras" :key="distribuidora.codigo" :value="distribuidora.codigo">
                  {{distribuidora.nombre}}
                </option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <base-input label="Tarifa">
              <select class="form-control" id="tarifa" v-model="dataPlanta.tarifa">
                <option v-for="tarifa in tarifas" :key="tarifa.codigo" :value="tarifa.codigo">
                  {{tarifa.codigo}}
                </option>
              </select>
            </base-input>
          </div>
          <div class="col-md-4">
            <br>
            <button class="btn" @click="guardarPlanta">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

export default {
  name: 'planta-ingeteam-create-page',
  components: {
    DatePicker,
    Multiselect
  },
  data() {
    return {
      selectedPlanta: null,
      selectedComuna: null,
      optionsPlantas: [],
      dataPlanta: {
        PvSystemId: '',
        nombre: null,
        nombre_visible: null,
        codigo: null,
        n_paneles: null,
        p_nom: null,
        p_peak: null,
        coef_pr_garantizado: null,
        disp_garantizado: null,
        fecha_inicio: null,
        tipo_contrato: null,
        porc_contrato: null,
        tipo_instalacion: null,
        tiene_smart_meter: false,
        tiene_sensor_irr: false,
        portafolio: '',
        tipo_reporte: 'SINREPORTEFINANCIERO',
        tipo: 'INGETEAM',
        pos_portafolio: null,
        zona_horaria: 'America/Santiago',
        es_pmgd: false,
        pmgd_trasera: 0.0,
        periodo: '5MIN',
        degradacion_primer_ano: 0,
        degradacion_lineal: 0,
        distribuidora: '',
        comuna: '',
        tarifa: '',
        tipo_descuentos: '',
        tipo_descuentos_autoconsumo: '',
        usa_dcto_diferida: false,
        porc_contrato_autoconsumo: 0,
      },
      portafolios: [],
      distribuidoras: [],
      comunas: [],
      tipos_descuentos: [],
      tarifas: [],
      periodos: [
        {key: '5MIN' ,label: '5 min'},
        {key: '15MIN' ,label: '15 min'},
        {key: '30MIN' ,label: '30 min'},
        {key: '1H' ,label: '1 hora'},
      ]
    }
  },

  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    // Reemplaza los datos en el formulario por los
    // de la planta seleccionada
    plantaSeleccionada(planta){
      let dataPlanta = {...this.dataPlanta};
      dataPlanta.nombre = planta.name;
      dataPlanta.nombre_visible = planta.name;
      // dataPlanta.p_peak = planta.peakPower/1000;
      dataPlanta.PvSystemId = planta.id; 

      this.dataPlanta = dataPlanta;
    },

    async guardarPlanta(){
      let dataPlanta = this.dataPlanta;
      // validar datos
      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/plantas',
          data: dataPlanta
        });

        let planta = res.data;

        this.$router.push({ name:'planta', params: { id: planta.id }});
      } catch (error) {
        this.notifyVue('Error al guardar Planta', 'danger');
        console.log(error);
      }
    },
  },

  async mounted(){
    // Obtiene un listados de plantas en SolarWeb
    try {
      let res_devices = await this.$http({
        method: 'GET',
        url: '/api/ingeteam/plantas',
      });

      this.optionsPlantas = res_devices.data;

      let res_portafolios = await this.$http({
        method: 'GET',
        url: '/api/portafolios/min',
      });

      this.portafolios = res_portafolios.data;

      let res_distribuidoras = await this.$http({
        method: 'GET',
        url: '/api/distribuidoras/min',
      });

      this.distribuidoras = res_distribuidoras.data;

      // pedir listado de comunas
      let res_comunas = await this.$http({
        method: 'GET',
        url: '/api/comunas/min',
      });

      this.comunas = res_comunas.data;

      // pedir listado de tarifas
      let res_tarifas = await this.$http({
        method: 'GET',
        url: '/api/tarifas/min',
      });

      this.tarifas = res_tarifas.data;

      // pedir listado de tipos_descuentos
      let res_tipos_descuentos = await this.$http({
        method: 'GET',
        url: '/api/tipos_descuentos/min',
      });

      this.tipos_descuentos = res_tipos_descuentos.data;
    } catch (error) {
      console.log(error);
    }
  },

  watch: {
    selectedComuna(comuna){
      if(comuna !== null){
        this.dataPlanta.comuna = comuna.codigo;
      }
      else{
        this.dataPlanta.comuna = '';
      }
    }
  }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>