<template>
  <div
    class="content"
    :class="{collapsed: $sidebar.showSidebar}"
  >
    <fade-transition :duration="100" mode="out-in">
      <!-- your content here -->
      <router-view></router-view>
    </fade-transition>
  </div>
</template>
<script>
  import {FadeTransition} from 'vue2-transitions';
  export default {
    components: {
      FadeTransition
    }
  };
</script>

<style lang="scss" scoped>

.collapsed {
  padding: 70px 30px 30px 30px !important;
  transition: padding 1s;
}
</style>
