<template>
<div>
  <div class="container">
    <div class="header-body text-center">
      <h1>Crear Usuario</h1>
    </div>
  </div>
  <div class="container">
    <div class="col-md-6 mt-3 ml-auto mr-auto">
      <form @submit.prevent="handleSubmit()">
        <card>
          <div>
            <base-input
              required
              v-model="first_name"
              type="text"
              placeholder="Nombre"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>
            <base-input
              required
              v-model="last_name"
              type="text"
              placeholder="Apellido"
              addon-left-icon="tim-icons icon-single-02"
            >
            </base-input>
            <base-input
              required
              v-model="email"
              type="email"
              placeholder="Correo"
              addon-left-icon="tim-icons icon-email-85"
            >
            </base-input>

            <base-input
              required
              v-model="password"
              placeholder="Contraseña"
              addon-left-icon="tim-icons icon-lock-circle"
              type="password"
             >
            </base-input>

            <base-input
              required
              v-model="password2"
              placeholder="Repetir Contraseña"
              addon-left-icon="tim-icons icon-lock-circle"
              type="password"
             >
            </base-input>
            <multiselect
              v-model="grupo" :options="grupos"
              placeholder="Seleccionar Rol" label="name" track-by="id">
            </multiselect>
          </div>

          <div slot="footer">
            <base-button native-type="submit" type="success" class="mb-3" size="lg" block>
              Crear
            </base-button>
          </div>
        </card>
      </form>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'crear-usuario',
  data(){
    return {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password2: '',
      grupo: null,
      grupos: [],
    }
  },
  methods: {
    async handleSubmit(){
      if(this.grupo == null){
        this.$notify({
          message:'Debe seleccionar un grupo',
          icon: 'tim-icons icon-bell-55',
          type: 'warning'
        });
        return;
      }
      try {
        let res = await this.$http({
          method: 'POST',
          url: 'api/registration/',
          data: {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            password2: this.password2,
            grupo: this.grupo.id
          }
        })

        this.$notify({
          message:'Usuario creado exitosamente!',
          icon: 'tim-icons icon-bell-55',
          type: 'success'
        });

        this.$router.push({name:'usuarios'}).catch(failure => {
          if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
            // show a small notification to the user
            console.log('Error')
          }
        })
      } catch (error) {
        this.$notify({
          message:'Error al crear usuario',
          icon: 'tim-icons icon-bell-55',
          type: 'danger'
        });
        console.log(error);
      }
    }
  },
  async mounted(){

    try {
      let res_grupos = await this.$http({
        method: 'GET',
        url: '/api/grupos'
      });

      this.grupos = res_grupos.data;
    } catch (error) {
      this.notifyVue('No se han podido cargar los datos de los roles', 'danger');
      console.log(error);
    }
  }
}
</script>

<style>

</style>