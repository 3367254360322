<template>
  <div>
    <!-- Modal para crear sensor -->
    <modal 
      :show.sync="modalCrear.show"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <small>Agregar Sensor</small>
          </div>
          <form role="form">
            <label class="typo__label">Tipo de Sensor</label>
            <multiselect 
              v-model="modalCrear.tipo"
              :options="tipos"
              track-by="key"
              label="label"
              placeholder="Seleccionar Tipo"
              @select="bloquearDeviceId"
            >
            </multiselect>
            <base-input 
              label="Nombre del Sensor"
              placeholder="Nombre del Sensor"
              v-model="modalCrear.nombre"
            >
            </base-input>
            <base-input 
              label="Id del Sensor"
              placeholder="DeviceId"
              v-model="modalCrear.deviceId"
              :disabled="deviceIdBloqueado"
            >
            </base-input>
            <base-input label="Periodo de Datos">
              <select class="form-control" id="periodo" v-model="modalCrear.periodo">
                <option v-for="periodo in periodos" :key="periodo.key" :value="periodo.key">{{periodo.label}}</option>
              </select>
            </base-input>
          </form>
        </template>  
      </card>
      <template slot="footer">
        <base-button type="primary" @click="crearSensor()">
          Guardar Datos
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="cerrarModalCrear">
          Cancelar
        </base-button>
      </template>
    </modal>

    <!-- Modal para eliminar sensor -->
    <modal 
      :show="modalEliminar.show"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-lg"
    >
      <template slot="header">
        <h1>Advertencia!</h1>
      </template>
      <card 
        type="secondary"
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        class="border-0 mb-0"
      >
        <template>
          <h2>Se eliminarán todos los datos del sensor seleccionado</h2>
          <h3 v-if="modalEliminar.sensor">{{modalEliminar.sensor.nombre}}</h3>
        </template>
      </card>
      <template slot="footer">
        <base-button type="primary" @click="eliminarSensor(modalEliminar.sensor)">
          Aceptar
        </base-button>
        <base-button type="secondary" class="ml-auto" @click="cerrarModalEliminar">
          Cancelar
        </base-button>
      </template>
    </modal>

    <div class="row">
      <div class="col-8">
        
    <h1>Sensores de Irradiación</h1>
      </div>
      <div class="col-4">
        <button class="btn" @click="abrirModalCrear">Agregar Sensor</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <div class="table-responsive">
            <table class="table tablesorter table-hover">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Tipo</th>
                  <th>Periodo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in sensores" :key="index">
                  <td>
                    <router-link
                      :to="{ name: 'sensor', params: { id: item.id }}"
                    >
                      {{item.nombre}}
                    </router-link>
                  </td>
                  <td>
                    {{item.tipo}}
                  </td>
                  <td>
                    {{item.periodo}}
                  </td>
                  <td>
                    <button
                      v-if="false"
                      class="btn btn-danger"
                      @click="abrirModalEliminar(item)"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Modal from '@/components/Modal';
import Multiselect from 'vue-multiselect';

// var backend_db_instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_DB_URL,
// });

Vue.component('multiselect', Multiselect);

export default {
  name: 'sensores-page',
  components: {
    Modal,
    Multiselect,
  },
  computed: {
  },
  data() {
    return {
      columns: ["id", "nombre"],
      sensores: [],
      deviceIdBloqueado: false,
      modalCrear: {
        show: false,
        deviceId: '',
        deviceName: '--',
        nombre: '',
        tipo: '',
        periodo: '1H'
      },
      modalEliminar: {
        show: false,
        sensor: null
      },
      periodos: [
        {key: '5MIN' ,label: '5 min'},
        {key: '15MIN' ,label: '15 min'},
        {key: '30MIN' ,label: '30 min'},
        {key: '1H' ,label: '1 hora'},
      ],
      tipos: [
        { key: 'FRONIUS',label: 'Fronius'},
        { key: 'SMA',label: 'SMA'},
        { key: 'HUAWEI',label: 'Huawei'},
        { key: 'HUAWEIPMGD',label: 'Huawei PMGD'},
        { key: 'HUAWEIPT',label: 'Huawei Portugal'},
        { key: 'HUAWEIFS',label: 'FusionSolar'},
        { key: 'SOLARGIS',label: 'Solargis'},
        { key: 'EXTERNO',label: 'Externo'},
        { key: 'SUNGOW',label: 'Sungrow'},
      ]
    };
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    bloquearDeviceId(valor){
      let tipo = valor.tipo;

      if(tipo === 'EXTERNO'){
        this.deviceIdBloqueado = true;
        if(this.modalCrear.deviceId == ''){
          this.modalCrear.deviceId = '--';
        }
        
      }
      else {
        this.deviceIdBloqueado = false;
      }
    },
    
    abrirModalCrear(){
      this.modalCrear.show = true;
    },

    cerrarModalCrear(){
      this.modalCrear = {
        show: false,
        deviceId: '--',
        deviceName: '--',
        nombre: '',
        periodo: '1H',
        tipo: null,
      }
    },

    async crearSensor(){
      try {
        let datos = {...this.modalCrear};
        datos.tipo = this.modalCrear.tipo.tipo;

        let res = await this.$http({
          method: 'POST',
          url: '/api/sensores',
          data: datos
        });

        this.notifyVue('Sensor guardado', 'success');
        this.sensores.push(res.data);

        this.cerrarModalCrear();
      } catch (error) {
        console.log(error);
        this.notifyVue('Error al guardar sensor', 'danger');
      }
    },

    abrirModalEliminar(sensor){
      this.modalEliminar.sensor = sensor;
      this.modalEliminar.show = true;
    },

    cerrarModalEliminar(){
      this.modalEliminar.show = false;
      this.modalEliminar.sensor = null;
    },

    async eliminarSensor(sensor){
      try {
        let res = await this.$http({
          method: 'DELETE',
          url: '/api/sensores/'+sensor.id
        });

        // this.sensors = res.data;
        console.log('Eliminada');
        this.sensores = this.sensores.filter(item => item.id != sensor.id);
        
        this.modalEliminar.show = false;
        this.modalEliminar.sensor = null;

        this.notifyVue('Se ha eliminado el sensor', 'success');
      } catch (error) {
        this.notifyVue('No se ha podido eliminar el sensor', 'danger');
        console.log(error);
      }
    }
  },
  async mounted(){
    try {
      let res = await this.$http({
        method: 'GET',
        url: '/api/sensores'
      });

      this.sensores = res.data;

    } catch (error) {
      console.log(error);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
</style>
