import SideBar from "@/components/SidebarPlugin";
import InvitadoSideBar from "@/components/InvitadoSidebarPlugin";
import Notify from "@/components/NotificationPlugin";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

//css assets
import "@/assets/sass/black-dashboard.scss";
import "@/assets/css/nucleo-icons.css";
// import "@/assets/css/custom.css";

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(InvitadoSideBar);
    Vue.use(Notify);
  }
}
