<template>
  <div class="wrapper">
    <invitado-side-bar backgroundColor="delta">
    </invitado-side-bar>
    <div class="main-panel">
      <visita-navbar></visita-navbar>

      <dashboard-content>

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
  import VisitaNavbar from "./VisitaNavbar.vue";
  import ContentFooter from "./SampleFooter.vue";
  import DashboardContent from "./Content.vue";
  // import MobileMenu from "./MobileMenu";
  export default {
    components: {
      VisitaNavbar,
      ContentFooter,
      DashboardContent,
      // MobileMenu
    },
    methods: {
    },

    mounted(){
      
    }
  };
</script>

<style lang="scss">
</style>