// import Vue from "vue";
import router from "../../router";
// import VueAuthenticate from "vue-authenticate";

// import axios from "axios";
// import VueAxios from "vue-axios";
// Vue.use(VueAxios, axios);

// const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
//   baseUrl: process.env.VUE_APP_API_BASE_URL,
//   tokenName: "access_token",
//   loginUrl: "/login",
//   registerUrl: "/register"
// });

// export default {
//   state: {
//     isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null
//   },

//   getters: {
//     isAuthenticated(state) {
//       return state.isAuthenticated;
//     }
//   },

//   mutations: {
//     isAuthenticated(state, payload) {
//       state.isAuthenticated = payload.isAuthenticated;
//     }
//   },

//   actions: {
//     login(context, payload) {
//       return vueAuth.login(payload.user, payload.requestOptions).then(response => {
//         context.commit("isAuthenticated", {
//           isAuthenticated: vueAuth.isAuthenticated()
//         });
//         router.push({path: "/dashboard"});
//       });
//     },

//     register(context, payload) {
//       return vueAuth.register(payload.user, payload.requestOptions).then(response => {
//         context.commit("isAuthenticated", {
//           isAuthenticated: vueAuth.isAuthenticated()
//         });
//         router.push({path: "/dashboard"});
//       });
//     },

//     logout(context, payload) {
//       return vueAuth.logout().then(response => {
//         context.commit("isAuthenticated", {
//           isAuthenticated: vueAuth.isAuthenticated()
//         });
//         router.push({name: "Login"});
//       });
//     }
//   }
// };
import Vue from 'vue';
import axios from "axios";
import { get } from "lodash";

var backend_db_instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_DB_URL,
});

export default {
  state: {
    // isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null
    isAuthenticated: false,
    status: '',
    access_token: localStorage.getItem('access_token') || '',
    refresh_token: localStorage.getItem('refresh_token') || '',
    user : {},
    groups: []
  },
    
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    isLoggedIn: state => !!state.access_token,
    authStatus: state => state.status,
    getUser: state => state.user,
    getGroups: state => state.groups,
    isInGroup: (state) => (group) => state.groups.some(groups => groups.name==group)
  },
    
  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },

    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, payload){
      state.status = 'success';
      state.access_token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      state.user = payload.user;
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = '';
      state.access_token = '';
      state.refresh_token = '';
      state.user = {};

    },
    checkFailed(state){
      state.status = '';
      state.access_token = '';
      state.refresh_token = '';
      state.user = {};
    },
    setGroups(state, groups){
      state.groups = groups;
    }
  },

  actions: {
    login({commit}, payload){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        backend_db_instance({
          method: 'POST',
          url: '/api/auth/login/',
          data: payload
        })
        .then(resp => {
          // console.log(resp);
          let access_token = resp.data.access_token;
          let refresh_token = resp.data.refresh_token
          let user = resp.data.user;

          localStorage.setItem('access_token', access_token);
          localStorage.setItem('refresh_token', refresh_token);

          axios.defaults.headers.common['Authorization'] = access_token;
          Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
          commit('auth_success', {access_token, refresh_token, user})
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },

    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },

    async checkStatus({commit, state}){
      let refresh = true;
      try {
        await backend_db_instance.post(
          'api/auth/token/verify/',
          {
            token: state.access_token
          }
        );

        await backend_db_instance.get(
          'api/usuario/activo',
          {
            headers: {
              'Authorization': `Bearer ${state.access_token}`
            }
          }
        );


      } catch (error) {
        console.error(error);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        delete axios.defaults.headers.common['Authorization']
        commit('checkFailed');
      }
    },

    async fetchGroups({commit, state}){
      try {
        const res = await backend_db_instance.get(
          'api/usuario',
          {
            headers: {
              'Authorization': `Bearer ${state.access_token}`
            }
          }
        );

        // set group
        commit('setGroups', res.data.groups);

      } catch (error) {
        console.error(error);
        // localStorage.removeItem('access_token');
        // localStorage.removeItem('refresh_token');
        // delete axios.defaults.headers.common['Authorization']
        // commit('checkFailed');
      }
    },

    refreshToken({commit}){
      const refreshUrl = "v1/auth/token/refresh/";

      return new Promise((resolve, reject) => {
        commit('auth_request')
        backend_db_instance({
          method: 'POST',
          url: '/api/auth/token/refresh',
          data: {
            refresh: state.refresh_token
          }
        })
        .then(resp => {
          console.log(resp);
          // let access_token = resp.data.access_token;
          // let refresh_token = resp.data.refresh_token
          // let user = resp.data.user;

          // localStorage.setItem('access_token', access_token);
          // localStorage.setItem('refresh_token', refresh_token);

          // axios.defaults.headers.common['Authorization'] = access_token;
          // Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
          // commit('auth_success', {access_token, refresh_token, user})
          // resolve(resp)
        })
        .catch(err => {
          // commit('auth_error')
          // localStorage.removeItem('token')
          reject(err)
        })
      })
    }
  }
}